import {
  Route,
  Redirect,
} from 'react-router-dom'
import { useAuth } from './auth';

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth()

  return (
    <Route {...rest} render={({ location }) => {
      return auth.user
        ? children
        : <Redirect to={{
            pathname: '/login',
            state: { from: location }
          }} />
    }} />
  )
}

export { PrivateRoute }
