import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@mui/material';

import { CHANGES_QUERIES, DUMMY_QUERY } from './queries'
import { useQuery } from '@apollo/client';

const PREFIX = 'Activities';

const classes = {
  block: `${PREFIX}-block`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('span')(() => ({
  [`& .${classes.block}`]: {
    display: 'block',
  }
}));

function getActionVerb(action) {
  if (action === 0) return 'created'
  return 'edited'
}

const Activity = ({activity}) => {

  const action = getActionVerb(activity.action)
  const actor = activity.actor?.name || 'System'
  const timestamp = new Date(activity.timestamp).toLocaleString()
  return (
    <ListItem alignItems="flex-start" key={activity.id}>
      <ListItemAvatar>
        <Avatar></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${actor} ${action} this record`}
        secondary={
          <Root>
            <Typography
              component="span"
              variant="body2"
              className={classes.block}
              color="textPrimary"
            >
              {timestamp}
            </Typography>
            {activity.changesStr}
          </Root>
        }
      />
    </ListItem>
  );
}


const Activities = ({name, rowId}) => {
  const skipChangesQuery = !CHANGES_QUERIES[name] || !rowId || rowId < 0
  const { loading, error, data } = useQuery(CHANGES_QUERIES[name] || DUMMY_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: skipChangesQuery,
    variables: { id: rowId },
  })

  var activityContent;
  if (loading) {
    activityContent = "Loading..."
  } else if (error) {
    console.log('error: ', error)
    activityContent = "Error :("
  } else {
    const activities = data?.[`${name}Log`] || []
    activityContent = (
      <List>
        { activities.map((activity) => (<Activity key={activity.id} activity={activity}/>)) }
      </List>
    )
  }

  return (
    <React.Fragment>
      <Typography variant="h6">Activities</Typography>
      {activityContent}
    </React.Fragment>
  )
}

export default Activities
