import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from "@devexpress/dx-react-core";
import {
  Typography,
} from '@mui/material';

export const RowCount = ({totalRowCount}) => (
  <Plugin>
    <Template name="toolbarContent">
      <TemplateConnector>
        {() => <Typography className="numRecords"> Number of Records: {totalRowCount}</Typography>}
      </TemplateConnector>
      <TemplatePlaceholder />
    </Template>
  </Plugin>
)
