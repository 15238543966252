import MainGrid from './MainGrid'
import { mediatorColumns as columns } from './columns'
import { getReportCardParams, getAvailableValues } from './utils'
import Spinner from './Spinner';


const editingColumnExtensions = [
  {
    columnName: 'name',
    createRowChange: (row, value) => ({ user: { ...row.user, name: value } }),
  },
  {
    columnName: 'email',
    createRowChange: (row, value) => ({ user: { ...row.user, email: value } }),
  },
  {
    columnName: 'city',
    createRowChange: (row, value) => ({ address: { ...row.address, city: value } }),
  },
  {
    columnName: 'postalCode',
    createRowChange: (row, value) => ({ address: { ...row.address, postalCode: value } }),
  },
  {
    columnName: 'poBoxNumber',
    createRowChange: (row, value) => ({ address: { ...row.address, poBoxNumber: value } }),
  },
]

const defaultHiddenColumnNames = ['dateOfBirth', 'postalCode', 'poBoxNumber', 'city']

export default function Mediator(props) {
  if (!props.rawMetaData) {
    return <Spinner />
  }

  const availableValues = getAvailableValues(props.rawMetaData, columns)

  const defaultStatusFilterValue = availableValues.status.find(c => c.name === "Active")

  return (
    <MainGrid
      columns={columns}
      availableValues={availableValues}
      editingColumnExtensions={editingColumnExtensions}
      defaultFilters={[{columnName: 'status', value: defaultStatusFilterValue}]}
      defaultHiddenColumnNames={defaultHiddenColumnNames}
      defaultFriendlyIDValue='Mediator Details'
      getReportCardParams={getReportCardParams}
      {...props}
    />
  )
}
