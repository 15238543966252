import React from 'react';
import { Button, IconButton } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import HeightIcon from '@mui/icons-material/Height';

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      onClick={onExecute}
      title="Create new record"
    >
      <AddIcon /> CREATE
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit record">
    <EditIcon />
  </IconButton>
);

const ViewButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="View record">
    <HeightIcon style={{ transform: "rotate(45deg)" }} />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm('Are you sure you want to delete this record?')) {
        onExecute();
      }
    }}
    title="Delete record"
  >
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute, disabled }) => (
  <IconButton onClick={onExecute} disabled={disabled} title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const CommandButtons = (readOnly) => {
  if (readOnly) {
    commandComponents.edit = ViewButton
  } else {
    commandComponents.edit = EditButton
  }

  return ({ id, onExecute, disabled }) => {
    const CommandButton = commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
        disabled={disabled}
      />
    );
  };
}

export default CommandButtons
