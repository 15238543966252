const SELECT_FALLBACK_VALUE = {id: null, name: ''}

const selectValueLookup = (availableColumnValues, value, isInGrid, defaultValue) => {
  const fallback = defaultValue ? defaultValue : SELECT_FALLBACK_VALUE
  if (!value && isInGrid) return fallback
  if (!availableColumnValues) console.log('Missing availableColumnValues', value)
  return availableColumnValues.find((v) => (value?.id === v.id)) || null
}

function wrapSelectChangeEvent(name, val, defaultValue, isFilterEditor) {
  const wrapped = { target: { name } }
  wrapped.target.value = { id: val?.id || defaultValue.id }
  if (isFilterEditor)
    wrapped.target.value['name'] = val?.name
  return wrapped
}

const getAvailableValues = (data, columns) => (columns.reduce((cumm, curr) => {
  if (!data)
    return cumm

  if (['select', 'multiselect'].includes(curr.type)) {
    const propKey = curr?.dataName ? curr.dataName : curr.name
    if (data[propKey]) {
      cumm[curr.name] = data[propKey]
    } else {
      console.log(`Missing available values for ${curr.type} for column ${curr.name}`)
    }
  }
  return cumm
}, {}))

const getReportCardParams = (row) => (row?.pk ? {mediator_id: row.pk} : undefined)

// taken from https://stackoverflow.com/a/32922084/429288
function deepEqual(x, y) {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}

function parseCitation(citation) {
  let cite = citation.replace('.', '')
  let deceased;
  if (citation.toLowerCase().includes('estate of')) {
    cite = cite.replace(/the matter of/i, '').replace(/in\s+the/i, '');
    [cite, deceased] = cite.split(/estate of/i, 2)
  }
  let [plaintiff, defendant] = cite.split(/\s+-?VS-?|VERSUS\s+/, 2)
  return [plaintiff?.trim(), defendant?.trim(), deceased?.trim()||null]
}


export {
  SELECT_FALLBACK_VALUE,
  selectValueLookup,
  wrapSelectChangeEvent,
  getAvailableValues,
  getReportCardParams,
  deepEqual,
  parseCitation,
}

