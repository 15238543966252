import React from 'react';
import {
  Grid,
  Alert,
  AlertTitle
} from '@mui/material';

const MediatorAssignmentIssues = ({issues}) => {
  return (!!issues.length &&
    <Grid item xs={12} style={{paddingTop: '8px'}}>
      <Alert severity="error">
        <AlertTitle>There are issues with this mediator selection</AlertTitle>
          { issues.map((issue, i) => (
            <span key={i}>{issue}<br/></span>
          )) }
      </Alert>
    </Grid>
  )
}

export default MediatorAssignmentIssues
