import React from 'react';
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const LABEL = "Open in CTS"

function CTSCaseLink({ctsCaseId}) {
  if (!ctsCaseId) return ''

  return (
    <Tooltip title={LABEL} arrow placement="bottom">
      <IconButton
        aria-label={LABEL}
        style={{ padding: "3px" }}
        href={`https://cts.court.go.ke/index.php/case_details/view_case_details/${ctsCaseId}`}
        target="_blank">
        <OpenInNewIcon />
      </IconButton>
    </Tooltip>
  )
}

export { CTSCaseLink }
