import React from 'react';
import {
  FormGroup,
  Grid,
  Divider,
  Typography,
} from '@mui/material';

import { mediatorColumns } from './columns'
import { ReportCardViewSwitcher } from './ReportCardViewSwitcher';
import ReportCard from './ReportCard';
import { PopupEditField } from './PopupEditor'
import { selectValueLookup, getReportCardParams  } from './utils'

const ExtraMediatorPanel = ({mediator, availableValues}) => {
  const defaultView = 'dashboard'
  const [showReportCard, setShowReportCard] = React.useState(defaultView==='dashboard')

  const mediatorWithDetails = React.useMemo(() => (
    selectValueLookup(availableValues.mediators, mediator)
  ), [availableValues.mediators, mediator])

  const reportCardParams = getReportCardParams(mediatorWithDetails)

  const switchView = React.useCallback((newView) => {
    if (reportCardParams && newView === 'dashboard') {
      setShowReportCard(true)
    } else {
      setShowReportCard(false)
    }
  }, [setShowReportCard, reportCardParams])

  if (!mediatorWithDetails?.pk) return ''

  return (
    <React.Fragment>
      <Grid container spacing={1} className="mediator-panel-header">
        <Grid item xs={9}>
          <Typography gutterBottom variant="h6">Mediator: {mediatorWithDetails.user.name} </Typography>
        </Grid>
        <Grid item xs={3}>
          {(reportCardParams &&
            <ReportCardViewSwitcher currentView={defaultView} onChange={switchView} />)}
        </Grid>
      </Grid>
      <Divider />
      <ReportCard queryParams={reportCardParams} show={showReportCard} />
      { !showReportCard &&
        <FormGroup>
          {mediatorColumns.map((col, index) => (
            <PopupEditField
              row={mediatorWithDetails}
              col={col}
              availableValues={availableValues}
              key={index}
              index={index}
              readOnly={true}
            />
          ))}
        </FormGroup>
      }
    </React.Fragment>
  )
}

export default ExtraMediatorPanel
