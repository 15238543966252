import React from 'react';
import { ViewSwitcher } from './ViewSwitcher';

export default function Dashboard({href, pathName, queryParams}) {

  const paramStr = React.useMemo(() => {
    if (!queryParams) return ''

    const params = new URLSearchParams()
    for (const [key, value] of Object.entries(queryParams)) {
      if (Array.isArray(value)) {
        for (const v of value) {
          params.append(key, v)
        }
      } else {
        params.append(key, value)
      }
    }
    return params.toString() ? '?' + params.toString() : ''
  }, [queryParams])

  return (
    <div style={{ textAlign: "center" }} >
      <ViewSwitcher pathName={pathName} />
      <iframe
        style={{
          width: "100%",
          minHeight: "80vh",
          height: "100%",
          border: "none",
          margin: 0,
          padding: 0,
          display: "block"
        }}
        title={pathName}
        src={href + paramStr}
      >
      </iframe>
    </div>
  )
}
