import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { PrivateRoute } from './Routing';
import './index.css';
import App from './App';
import SignIn from './signin';
import reportWebVitals from './reportWebVitals';
import { getClient } from './offixdb';
import { ApolloProvider } from '@apollo/client';
import { ApolloOfflineProvider } from '@weilu/react-offix-hooks'
import {
  Snackbar,
  Alert,
} from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ProvideAuth } from './auth'
import posthog from 'posthog-js'

Sentry.init({
  dsn: "https://a367ffa501ee4145a0a6527929385728@o613373.ingest.sentry.io/5795480",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 40,
        }
      }
    },
  },
  palette: {
    primary: {
      main: '#354113',
    },
    secondary: {
      main: '#C9C234',
    },
  },
})

function AppWithProviders() {

  const defaultMsgObj = {message: null, type: 'error'}
  const [msgObj, setMsgObj] = React.useState(defaultMsgObj)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMsgObj(defaultMsgObj)
  };

  const [initialized, setInitialized] = React.useState(false)
  const [client, setClient] = React.useState()

  React.useEffect(() => {
    const c = getClient(setMsgObj)
    setClient(c)

    // offix client requires initialization
    c.init().then(() => setInitialized(true))

    if (!window.location.href.includes('localhost')) {
      posthog.init("RWD7riERcAxfPlEbjNa-vNcU4PHTPniaB2q7L4Kb4ME",
        { api_host: 'https://kenya-mediation-hog.herokuapp.com' })
    }
  }, [])

  return (
  <React.StrictMode>
    {(client &&
      <ApolloOfflineProvider client={client}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            {(initialized &&
            <div className="app-routes">
              <ProvideAuth>
                <Router>
                  <Switch>
                    <Route path="/login">
                      <SignIn />
                    </Route>
                    <PrivateRoute path="/">
                      <App setMsgObj={setMsgObj} client={client} />
                    </PrivateRoute>
                  </Switch>
                </Router>
              </ProvideAuth>
            </div>
            )}
          <Snackbar
            open={!!msgObj.message}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleClose} severity={msgObj.type} sx={{ width: '100%' }}>
              {msgObj.message}
            </Alert>
          </Snackbar>
          </ThemeProvider>
        </ApolloProvider>
      </ApolloOfflineProvider>
    )}
  </React.StrictMode>
  )
}

ReactDOM.render(
  <AppWithProviders />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
