import { ApolloOfflineClient } from 'offix-client';
import { gql, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client';
import { fromPromise } from "apollo-link";
import { deleteUser, REFRESHED_FLAG } from "./auth";
import { MEDIATOR_FIELDS, CASE_FIELDS, OFFICER_FIELDS } from "./queries";
import {
  capitalize,
} from '@mui/material';

const httpLink = createUploadLink({
  uri: '/graphql/',
  credentials: "include",
})

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "X-CSRFToken": getCookie("csrftoken"),
    }
  }
})

const REFRESH_QUERY = gql`
mutation refreshToken {
  refreshToken {
    payload
  }
}
`

function getClient(setMsgObj) {
  const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
    const messages = []
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err && err.message) {
          switch (err.message) {
            case "You do not have permission to perform this action":
              return fromPromise(refresh()).flatMap(() => forward(operation));
            case "Invalid refresh token":
            case "Refresh token is required":
            case "User is disabled":
              deleteUser()
              if (!localStorage.getItem(REFRESHED_FLAG)) {
                localStorage.setItem(REFRESHED_FLAG, true);
                window.location.reload()
              }
              break
            default:
              continue
          }
        }
      }

      graphQLErrors.forEach(({ message, locations, path }) => {
        const idParseError = message.match(/Unable to parse global ID "(.+?)"/)
        const recordNotFound = message.match(/(.+?) matching query does not exist/)
        if (idParseError) {
          messages.push(`Record with ID ${idParseError[1]} not found`)
        } else if (recordNotFound) {
          messages.push(`${recordNotFound[1]} record not found`)
        } else {
          messages.push(message)
        }
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )},
      )
    }

    setMsgObj({message: messages.join('\n'), type: 'error'})
  })

  const link = from([
    authLink,
    errorLink,
    httpLink,
  ])

  const config = {
    link: link,
    cache: new InMemoryCache(),
    retryOptions: {
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 2,
        retryIf: (error, _operation) => {
          return !!error && error.statusCode !== 400
        }
      }
    }
  };
  const client = new ApolloOfflineClient(config)

  async function refresh() {
    await client.mutate({mutation: REFRESH_QUERY})
  }

  return client
}

const UPDATE_MEDIATOR_QUERY = gql`
  mutation updateMediator($accreditationCategories: [NestedObjectMutationPayload],
                          $address: AddressMutationPayload,
                          $dateOfBirth: String,
                          $id: ID!,
                          $macNumber: String,
                          $phone: String,
                          $professionalMemberships: [NestedObjectMutationPayload],
                          $professionalQualifications: [NestedObjectMutationPayload],
                          $professions: [NestedObjectMutationPayload],
                          $status: NestedObjectMutationPayload,
                          $user: UserMutationPayload,
                          $regions: [NestedObjectMutationPayload],
                          $gender: NestedObjectMutationPayload,
                          $religions: [NestedObjectMutationPayload],
                          $languages: [NestedObjectMutationPayload],
                          $courtStations: [NestedObjectMutationPayload],
                          $dateOfAccreditation: String,
                          $nationality: NestedObjectMutationPayload,
                          $idPassportNumber: String,
                          $virtualMediation: Boolean,
                          $image: Upload,
                          $remark: String,
                          ) {
    updateMediator(id: $id,
                   user: $user,
                   address: $address,
                   dateOfBirth: $dateOfBirth,
                   macNumber: $macNumber,
                   phone: $phone,
                   accreditationCategories: $accreditationCategories,
                   professionalMemberships: $professionalMemberships,
                   professionalQualifications: $professionalQualifications,
                   professions: $professions,
                   status: $status,
                   regions: $regions,
                   gender: $gender,
                   religions: $religions,
                   languages: $languages,
                   courtStations: $courtStations,
                   dateOfAccreditation: $dateOfAccreditation,
                   nationality: $nationality,
                   idPassportNumber: $idPassportNumber,
                   virtualMediation: $virtualMediation,
                   image: $image,
                   remark: $remark,
                   ) { mediator {
                   ${MEDIATOR_FIELDS}
                   } }
  }
`

const UPDATE_CASE_QUERY = gql`
  mutation updateCase(
    $id: ID!,
    $referralDate: String,
    $referralMode: NestedObjectMutationPayload,
    $courtStation: NestedObjectMutationPayload,
    $courtType: NestedObjectMutationPayload,
    $courtDivision: NestedObjectMutationPayload,
    $caseType: NestedObjectMutationPayload,
    $caseNumber: String,
    $originalCaseNumber: String,
    $caseValue: Float,
    $caseValueMode: NestedObjectMutationPayload,
    $plaintiffName: String,
    $plaintiffContact: String,
    $plaintiffLanguages: [NestedObjectMutationPayload],
    $defendantName: String,
    $defendantContact: String,
    $defendantLanguages: [NestedObjectMutationPayload],
    $deceasedName: String,
    $mediatorAppointmentDate: String,
    $conclusionDate: String,
    $completionCertificateDate: String,
    $forwardedForPaymentDate: String,
    $fileReturnedToCourtDate: String,
    $mediator: NestedObjectMutationPayload,
    $outcome: NestedObjectMutationPayload,
    $pendingReason: NestedObjectMutationPayload,
    $status: NestedObjectMutationPayload,
    $agreementMode: NestedObjectMutationPayload,
    $sessionType: NestedObjectMutationPayload,
    $remark: String,
    $ctsCaseId: Int,
  ) {
    updateCase(
      id: $id,
      referralDate: $referralDate,
      referralMode: $referralMode,
      courtStation: $courtStation,
      courtType: $courtType,
      courtDivision: $courtDivision,
      caseType: $caseType,
      caseNumber: $caseNumber,
      originalCaseNumber: $originalCaseNumber,
      caseValue: $caseValue,
      caseValueMode: $caseValueMode,
      plaintiffName: $plaintiffName,
      plaintiffContact: $plaintiffContact,
      plaintiffLanguages: $plaintiffLanguages,
      defendantName: $defendantName,
      defendantContact: $defendantContact,
      defendantLanguages: $defendantLanguages,
      deceasedName: $deceasedName,
      mediatorAppointmentDate: $mediatorAppointmentDate,
      conclusionDate: $conclusionDate,
      completionCertificateDate: $completionCertificateDate,
      forwardedForPaymentDate: $forwardedForPaymentDate,
      fileReturnedToCourtDate: $fileReturnedToCourtDate,
      mediator: $mediator,
      outcome: $outcome,
      pendingReason: $pendingReason,
      status: $status,
      agreementMode: $agreementMode,
      sessionType: $sessionType,
      remark: $remark,
      ctsCaseId: $ctsCaseId,
    ) { case {
      ${CASE_FIELDS}
    } }
  }
`

const UPDATE_OFFICER_QUERY = gql`
  mutation updateOfficer(
    $id: ID!,
    $email: String,
    $name: String,
    $isActive: Boolean,
    $groups: [NestedObjectMutationPayload],
  ) {
    updateOfficer(
      id: $id,
      email: $email,
      name: $name,
      isActive: $isActive,
      groups: $groups,
    ) {
      officer {
       ${OFFICER_FIELDS}
      }
    }
  }
`

const UPDATE_QUERIES = {
  mediator: UPDATE_MEDIATOR_QUERY,
  case:  UPDATE_CASE_QUERY,
  officer:  UPDATE_OFFICER_QUERY,
}

const CREATE_MEDIATOR_QUERY = gql`
  mutation createMediator($accreditationCategories: [NestedObjectMutationPayload],
                          $address: AddressMutationPayload,
                          $dateOfBirth: String,
                          $phone: String,
                          $professionalMemberships: [NestedObjectMutationPayload],
                          $professionalQualifications: [NestedObjectMutationPayload],
                          $professions: [NestedObjectMutationPayload],
                          $status: NestedObjectMutationPayload!,
                          $user: UserCreationPayload!,
                          $regions: [NestedObjectMutationPayload],
                          $gender: NestedObjectMutationPayload,
                          $religions: [NestedObjectMutationPayload],
                          $languages: [NestedObjectMutationPayload],
                          $courtStations: [NestedObjectMutationPayload],
                          $dateOfAccreditation: String,
                          $nationality: NestedObjectMutationPayload,
                          $idPassportNumber: String,
                          $virtualMediation: Boolean,
                          $image: Upload,
                          $remark: String,
                          ) {
    createMediator(user: $user,
                   address: $address,
                   dateOfBirth: $dateOfBirth,
                   phone: $phone,
                   accreditationCategories: $accreditationCategories,
                   professionalMemberships: $professionalMemberships,
                   professionalQualifications: $professionalQualifications,
                   professions: $professions,
                   status: $status,
                   regions: $regions,
                   gender: $gender,
                   religions: $religions,
                   languages: $languages,
                   courtStations: $courtStations,
                   dateOfAccreditation: $dateOfAccreditation,
                   nationality: $nationality,
                   idPassportNumber: $idPassportNumber,
                   virtualMediation: $virtualMediation,
                   image: $image,
                   remark: $remark,
                   ) {
                     mediator {
                      ${MEDIATOR_FIELDS}
                     }
                   }
  }
`

const CREATE_CASE_QUERY  = gql`
  mutation createCase(
    $referralDate: String!,
    $referralMode: NestedObjectMutationPayload!,
    $courtStation: NestedObjectMutationPayload!,
    $courtType: NestedObjectMutationPayload!,
    $courtDivision: NestedObjectMutationPayload,
    $caseType: NestedObjectMutationPayload!,
    $caseNumber: String,
    $originalCaseNumber: String!,
    $caseValue: Float,
    $caseValueMode: NestedObjectMutationPayload!,
    $plaintiffName: String,
    $plaintiffContact: String,
    $plaintiffLanguages: [NestedObjectMutationPayload],
    $defendantName: String,
    $defendantContact: String,
    $defendantLanguages: [NestedObjectMutationPayload],
    $deceasedName: String,
    $mediatorAppointmentDate: String,
    $conclusionDate: String,
    $completionCertificateDate: String,
    $forwardedForPaymentDate: String,
    $fileReturnedToCourtDate: String,
    $mediator: NestedObjectMutationPayload,
    $outcome: NestedObjectMutationPayload,
    $pendingReason: NestedObjectMutationPayload,
    $status: NestedObjectMutationPayload!,
    $agreementMode: NestedObjectMutationPayload,
    $sessionType: NestedObjectMutationPayload,
    $remark: String,
    $ctsCaseId: Int,
  ) {
    createCase(
      referralDate: $referralDate,
      referralMode: $referralMode,
      courtStation: $courtStation,
      courtType: $courtType,
      courtDivision: $courtDivision,
      caseType: $caseType,
      caseNumber: $caseNumber,
      originalCaseNumber: $originalCaseNumber,
      caseValue: $caseValue,
      caseValueMode: $caseValueMode,
      plaintiffName: $plaintiffName,
      plaintiffContact: $plaintiffContact,
      plaintiffLanguages: $plaintiffLanguages,
      defendantName: $defendantName,
      defendantContact: $defendantContact,
      defendantLanguages: $defendantLanguages,
      deceasedName: $deceasedName,
      mediatorAppointmentDate: $mediatorAppointmentDate,
      conclusionDate: $conclusionDate,
      completionCertificateDate: $completionCertificateDate,
      forwardedForPaymentDate: $forwardedForPaymentDate,
      fileReturnedToCourtDate: $fileReturnedToCourtDate,
      mediator: $mediator,
      outcome: $outcome,
      pendingReason: $pendingReason,
      status: $status,
      agreementMode: $agreementMode,
      sessionType: $sessionType,
      remark: $remark,
      ctsCaseId: $ctsCaseId,
    ) { case {
      ${CASE_FIELDS}
    } }
  }
`

const CREATE_OFFICER_QUERY = gql`
  mutation createOfficer(
    $email: String!,
    $name: String!,
    $isActive: Boolean!,
    $groups: [NestedObjectMutationPayload],
  ) {
    createOfficer(
      email: $email,
      name: $name,
      isActive: $isActive,
      groups: $groups,
    ) {
      officer {
       ${OFFICER_FIELDS}
      }
    }
  }
`

const CREATE_QUERIES = {
  mediator: CREATE_MEDIATOR_QUERY,
  case: CREATE_CASE_QUERY,
  officer: CREATE_OFFICER_QUERY,
}

const DELETE_QUERIES = {
  mediator: gql`
    mutation deleteMediator ($id: ID!) {
      deleteMediator(id: $id) { mediator { macNumber } }
    }
  `,
  case: gql`
    mutation deleteCase ($id: ID!) {
      deleteCase(id: $id) { case { caseNumber } }
    }
  `
}

function stripMetafields(obj) {
  // strip id and __typename fields as they are not allowed by API
  delete obj.id
  delete obj.__typename
}

const prepareRecordForAPI = (record) => {
  if (record.user != null) {
    stripMetafields(record.user)
  }

  if (record.address != null) {
    stripMetafields(record.address)
  }

  // handle select type of field clearing value
  for (const [key, value] of Object.entries(record)) {
    if (value && value.hasOwnProperty('id') && value.id === null) {
      record[key] = null
    }
  }
}

function getNextLocalId() {
  const key = 'LastMediatorLocalId'
  const lastId = localStorage.getItem(key) || 0
  const nextId = lastId - 1
  localStorage.setItem(key, nextId)
  return nextId
}

const OFFLINE_MSG = {message: 'No internet connection. Changes saved locally and will be uploaded when connected to internet.', type: 'info'}
const ONLINE_SUCCESS_MSG = {message: 'Internet connection restored. Local changes uploaded.', type: 'success'}
const ONLINE_ERROR_MSG = {message: 'Internet connection restored but failed to upload local changes. Please try again.', type: 'error'}

function handleErrors({
  error, setMsgObj, actionVerb, record, name, commitDeletedRows, updateRowIDs,
}) {
  if (error.offline) {
    const recordId = actionVerb === 'create' ? getNextLocalId() : record.id
    setMsgObj(OFFLINE_MSG)
    const promise = error.watchOfflineChange()
    switch (actionVerb) {
      case "create":
        promise
        .then((response) => {
          setMsgObj(ONLINE_SUCCESS_MSG)
          record.id = recordId
          updateRowIDs(record, response.data[`create${capitalize(name)}`][name])
        })
        .catch((err) => {
          console.error(`Offline client failed to create ${name}`, err)
          setMsgObj(ONLINE_ERROR_MSG)
          commitDeletedRows({rowIds: [recordId]})
        })
        break
      case "update":
      case "delete":
        promise
        .then((response) => {
          setMsgObj(ONLINE_SUCCESS_MSG)
        })
        .catch((err) => {
          console.error(`Offline client failed to update ${name}`, err)
          setMsgObj(ONLINE_ERROR_MSG)
          //TODO: update single record instead of full refresh
          window.location.reload()
        })
        break
      default:
        throw new Error(`Unknown actionVerb ${actionVerb}`)
    }
    return { id: recordId }
  } else if (error.networkError || error.graphQLErrors) {
    // message display already handled globally in errorLink, just need to return false
    return false
  } else {
    console.error(`Failed to ${actionVerb} ${name}`, error)
    setMsgObj({message: `Failed to ${actionVerb} ${name} record due to unexpected error: ${error}`, type: 'error'})
    return false
  }
}

async function commitCreateToServer({
  editedRow,
  createRecord,
  setMsgObj,
  availableValues,
  commitDeletedRows,
  updateRowIDs,
  name,
}) {
  prepareRecordForAPI(editedRow)

  try {
    const response = await createRecord({variables: editedRow})
    const record = response.data[`create${capitalize(name)}`][name]
    setMsgObj({message: 'Record created!', type: 'success'})
    return record
  } catch(error) {
    return handleErrors({
      actionVerb: 'create',
      record: editedRow,
      error, setMsgObj, name, commitDeletedRows, updateRowIDs,
    })
  }
}

async function commitUpdateToServer({
  editedRow, updateRecord, setMsgObj, availableValues, name
}) {
  prepareRecordForAPI(editedRow)

  try {
    const response = await updateRecord({variables: editedRow})
    const record = response.data[`update${capitalize(name)}`][name]
    setMsgObj({message: 'Record updated!', type: 'success'})
    return record
  } catch(error) {
    return handleErrors({
      actionVerb: 'update',
      record: editedRow,
      error, setMsgObj, name
    })
  }
}

async function commitDeleteToServer({deleted, deleteRecord, setMsgObj, name}) {
  console.assert(deleted.length === 1,
    {msg: 'Expect one row to be deleted at a time'})
  const record = {id: deleted[0]}

  if (record.id < 0) // local deletion, no need to propagate to server
    return true

  try {
    await deleteRecord({
      variables: record,
      update(cache) {
        const typeName = name[0].toUpperCase() + name.slice(1) + "Type"
        const normalizedId = cache.identify({ id: record.id, __typename: typeName });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    })
    setMsgObj({message: 'Record deleted!', type: 'success'})
    return true
  } catch(error) {
    return handleErrors({
      actionVerb: 'delete',
      error, setMsgObj, record, name
    })
  }
}

export {
  getClient,
  UPDATE_QUERIES,
  CREATE_QUERIES,
  DELETE_QUERIES,
  commitCreateToServer,
  commitUpdateToServer,
  commitDeleteToServer,
}
