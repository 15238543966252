import Resizer from "react-image-file-resizer";

const resizeImage = (file) => {
  // handle cases when the file is deleted on server
  if (file.type && !file.type.includes('image'))
    return new Promise((resolve) => resolve(null))

  let quality = file.size > 1000000 ? 90 : 100; // 1MB image file
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      600,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
};
export { resizeImage };
