import MainGrid from './MainGrid'
import { officerColumns as columns } from './columns'
import { getAvailableValues } from './utils'
import Spinner from './Spinner';


const defaultHiddenColumnNames = []
const editingColumnExtensions = []

export default function Officer(props) {
  if (!props.rawMetaData) {
    return <Spinner />
  }

  const availableValues = getAvailableValues(props.rawMetaData, columns)

  return (
    <MainGrid
      columns={columns}
      availableValues={availableValues}
      editingColumnExtensions={editingColumnExtensions}
      defaultHiddenColumnNames={defaultHiddenColumnNames}
      {...props}
    />
  )
}
