import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';


const PREFIX = 'ReportCardViewSwitcher';

const classes = {
  root: `${PREFIX}-root`
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    float: 'right',
    "& button": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "& button.MuiToggleButton-root.Mui-selected": {
      color: theme.palette.primary.main,
    },
  }
}));

export const ReportCardViewSwitcher = ({ currentView, onChange }) => {

  const [value, setValue] = React.useState(currentView)

  const handleToggle = (event, newValue) => {
    setValue(newValue)
    onChange(newValue)
  };

  return (
    <StyledToggleButtonGroup
      value={value}
      onChange={handleToggle}
      exclusive
      size="small"
      className={classes.root}
    >
      <ToggleButton value="data" >
        <Icon fontSize="small" className="material-icons" color="primary">
           portrait
        </Icon>
      </ToggleButton>
      <ToggleButton value="dashboard" >
        <Icon fontSize="small" className="material-icons-outlined" color="primary">
           insights
        </Icon>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
}
