import React from 'react';
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const LABEL = "copy link to clipboard"

function LinkToClipboard({show}) {
  const [tooltipMsg, setTooltipMsg] = React.useState(LABEL)

  const onCopyText = () => {
    setTooltipMsg('Copied!');
    setTimeout(() => {
      setTooltipMsg(LABEL);
    }, 1000);
  };

  if (!show) return ''

  return (
      <CopyToClipboard text={window.location.href} onCopy={onCopyText}>
        <Tooltip title={tooltipMsg} arrow placement="bottom">
          <IconButton aria-label={LABEL} style={{ padding: "3px" }}>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
  )
}

export { LinkToClipboard }
