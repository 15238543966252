// returns error message if any
// required is a separate arg as it is needed for conditional required fields
const validateReturnError = (column, required, val) => {
  if (column.validation) {
    const valid = column.validation.predicate(val)
    if (!valid) return column.validation.errMsg
  }

  if (column.type === 'date' && val) {
    if (column.min && val < column.min) {
      return `Date must be no earlier than ${column.min}`
    }
    if (column.max && val > column.max) {
      return `Date must be no later than ${column.max}`
    }
  }

  if (!required) return ''
  let present = false
  switch (column.type) {
    case 'multiselect':
      present = (val && val.length > 0) || (val?.edges && val.edges.length > 0)
      break
    case 'select':
      present = val && val.id
      break
    case 'boolean':
      present = val || (val === false)
      break
    default:
      present = !!val
  }
  return present ? '' : 'Required'
}

const isValidCell = ({column, row}) => {
  const val = column.getCellValue ? column.getCellValue(row) : row[column.name]
  const required = column.required || (column.conditional && column.conditional(row) && column.conditionallyRequired)
  return !validateReturnError(column, required, val)
}

const hasError = ({row, columns}) => {
  const mediatorRecommenderError = columns.map(c => c.name).includes('mediator') && row.rejectRecommendation?.hasError
  const hasInvalidCell = columns.some(column => !isValidCell({column, row}))
  return hasInvalidCell || mediatorRecommenderError
}

const validate = ({isFilterEditor, value, column,
  required, initialValidate, rowChanges, editingRowIds}) => {

  if (!editingRowIds) return ''

  const [rowId] = editingRowIds;
  const columnChanged = rowChanges[rowId] && column.name in rowChanges[rowId]
  if (!isFilterEditor && (initialValidate || columnChanged)) {
    return validateReturnError(column, required, value)
  }

  return ''
}


export { hasError, validate }
