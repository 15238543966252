import React from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  DUMMY_QUERY,
  UNAVAILABILITY_QUERY,
  CREATE_UNAVAILABILITY_QUERY,
  UPDATE_UNAVAILABILITY_QUERY,
  DELETE_UNAVAILABILITY_QUERY,
} from './queries'
import { useQuery, useMutation } from '@apollo/client';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

function beforeToday(value) {
  const today = new Date().toISOString().split("T")[0]
  return value && value < today
}

const UnavailabilityDateField = ({name, label, value, onChange, error, disabled}) => {
  const today = new Date().toISOString().split("T")[0]
  return (
     <TextField
       variant="standard"
       type="date"
       name={name}
       label={label}
       value={value || ''}
       onChange={onChange}
       required={true}
       InputLabelProps={{ shrink: true }}
       inputProps={{
         min: today,
       }}
       disabled={disabled || beforeToday(value)}
       fullWidth
       error={!!error}
       helperText={error}
    />
  )
}

const Unavailability = ({unavailability, onSaveCompleted, refetchQueries}) => {
  const [unavailableDates, setUnavailableDates] = React.useState(unavailability.unavailableDates || {});

  const [error, setError] = React.useState('')
  const onError = (err) => {
    const errMsg = err.graphQLErrors ? err.graphQLErrors[0].message : err.message
    setError(errMsg)
  }

  const onCompleted = (payload) => {
    setError('')

    const ua = Object.values(payload)[0].mediatorUnavailability
    ua.mediator = unavailability.mediator
    ua.mediationCaseNumber = unavailability.mediationCaseNumber
    onSaveCompleted(ua)
  }

  const [createRecord] = useMutation(CREATE_UNAVAILABILITY_QUERY, {
      onCompleted, onError, refetchQueries,
  })
  const [updateRecord] = useMutation(UPDATE_UNAVAILABILITY_QUERY, {
      onCompleted, onError, refetchQueries,
  })
  const [deleteRecord] = useMutation(DELETE_UNAVAILABILITY_QUERY, {
      onCompleted, onError, refetchQueries,
  })

  const saveToDB = React.useCallback((dates) => {
    const shouldSave = dates[0] && dates[1]
    if (!shouldSave) return


    if (unavailability.id <= 0) {
      createRecord({variables: {
        mediator: unavailability.mediator,
        mediationCaseNumber: unavailability.mediationCaseNumber,
        unavailableDates: dates
      }})
    } else {
      updateRecord({variables: {
        id: unavailability.id,
        mediationCaseNumber: unavailability.mediationCaseNumber,
        unavailableDates: dates
      }})
    }
  }, [createRecord, updateRecord, unavailability])

  const onStartChange = (event) => {
    const newStartDate = event.target.value
    if (newStartDate != unavailableDates[0]) {
      const dates = [newStartDate, unavailableDates[1]]
      setUnavailableDates(dates)
      saveToDB(dates)
    }
  }
  const onEndChange = (event) => {
    const newEndDate = event.target.value
    if (newEndDate != unavailableDates[1]) {
      const dates = [unavailableDates[0], newEndDate]
      setUnavailableDates(dates)
      saveToDB(dates)
    }
  }

  const deleteUnavailability = () => {
    if (unavailability.id < 0) { // deleting an unsaved record
      return onSaveCompleted({})
    }

    deleteRecord({variables: {
      id: unavailability.id,
      mediationCaseNumber: unavailability.mediationCaseNumber,
    }})
  }

  const deleteDisabled = (unavailability.id > 0 && beforeToday(unavailableDates[0])) || unavailability.studyAutoBlocked

  return (
      <Grid container justifyContent="space-between" spacing={1} alignItems="center">
        <Grid item xs={5}>
          <UnavailabilityDateField
            name='start' label='Start'
            value={unavailableDates[0]}
            onChange={onStartChange}
            error={error}
            disabled={unavailability.studyAutoBlocked}
          />
        </Grid>
        <Grid item xs={1} style={{textAlign: "center"}}>
          <Typography gutterBottom variant="h7"> to </Typography>
        </Grid>
        <Grid item xs={5}>
          <UnavailabilityDateField
            name='end' label='End'
            value={unavailableDates[1]}
            onChange={onEndChange}
            error={error}
            disabled={unavailability.studyAutoBlocked}
          />
        </Grid>
        <Grid item xs={1} style={{textAlign: "center"}}>
          <IconButton aria-label="delete" disabled={deleteDisabled} onClick={deleteUnavailability}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
  )
}

const Unavailabilities = ({mediatorId, caseNumber, setMsgObj, refetchQueries}) => {
  const [addButtonDisabled, setAddButtonDisabled] = React.useState(false);
  const [unavailabilities, setUnavailabilities] = React.useState([]);
  const [pastUnavailabilities, setPastUnavailabilities] = React.useState([]);
  const [futureUnavailabilities, setFutureUnavailabilities] = React.useState([]);
  const [addButtonText, setAddButtonText] = React.useState('ADD ANOTHER DATE');
  const onQueryComplete = React.useCallback((data) => {
    if (data?.['mediatorUnavailabilities']) {
      setUnavailabilities(data['mediatorUnavailabilities'].map(ua => ({
        ...ua,
        mediator: {id: mediatorId},
        mediationCaseNumber: caseNumber,
        id: parseInt(ua.id),
      })))
    }
  }, [mediatorId, caseNumber])
  const onQueryError = React.useCallback((error) => {
    //TODO: error handling
    console.error('Failed to fetch mediator unavailability', error)
  }, [])

  React.useEffect(() => {
    const future = []
    const past = []
    unavailabilities.forEach(ua => (
      beforeToday(ua.unavailableDates[1]) ?  past.push(ua) : future.push(ua)
    ))
    setPastUnavailabilities(past)
    setFutureUnavailabilities(future)
  }, [unavailabilities])

  const skipQuery = !mediatorId || mediatorId < 0
  useQuery(UNAVAILABILITY_QUERY || DUMMY_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: skipQuery,
    variables: { mediatorId },
    onCompleted: onQueryComplete,
    onError: onQueryError,
  })

  const addUnavailability = () => {
    const ua = {
      id: -parseInt(Math.random() * 1000),
      mediator: {id : mediatorId},
      mediationCaseNumber: caseNumber,
      unavailableDates: [null, null],
    }
    setUnavailabilities([...unavailabilities, ua])
    setAddButtonDisabled(true)
  }

  const onSaveCompleted = (index) => ((ua) => {
    const indexAccountForPast = index + pastUnavailabilities.length
    const uasBefore = unavailabilities.slice(0, indexAccountForPast)
    const uasAfter = unavailabilities.slice(indexAccountForPast+1)

    // delete query doesn't return id
    const uas = ua.id ? [...uasBefore, ua, ...uasAfter] : [...uasBefore, ...uasAfter]
    setUnavailabilities(uas)

    setAddButtonDisabled(uas.length > 0 && uas[uas.length-1].id < 0)

    // not deleted an unsaved entry
    if (Object.keys(ua).length != 0) {
      setMsgObj({message: 'Mediator unavailability saved!', type: 'success'})
    }
  })

  React.useEffect(() => {
    setAddButtonText(unavailabilities.length > 0 ? 'ADD ANOTHER DATE' : 'ADD DATE')
  }, [unavailabilities.length])

  if (!mediatorId) return ''

  return (
    <React.Fragment>
      <Typography variant="h6">Mediator Unavailability</Typography>
      <List>
        { futureUnavailabilities.map((ua, i) => (
          <ListItem key={ua.id}>
            <Unavailability
              unavailability={ua}
              onSaveCompleted={onSaveCompleted(i)}
              refetchQueries={refetchQueries}
            />
          </ListItem>
        )) }
        <ListItem key="new-button">
          <Button
            onClick={addUnavailability}
            title="Add another unavailable period"
            disabled={addButtonDisabled}
          >
            <AddIcon />{addButtonText}
          </Button>
        </ListItem>
      </List>
      {!!pastUnavailabilities.length &&
      <React.Fragment>
        <Typography variant="h7">Past Unavailability</Typography>
        <List>
          { pastUnavailabilities.map((ua, i) => (
            <ListItem key={`past${ua.id}`}>
              <Unavailability
                unavailability={ua}
                onSaveCompleted={onSaveCompleted(i)}
                refetchQueries={refetchQueries}
              />
            </ListItem>
          )) }
        </List>
      </React.Fragment>
      }
    </React.Fragment>
  )
}

export default Unavailabilities
