import React from 'react';

const href = 'https://kenya-mediation-dash.herokuapp.com/public/dashboard/b130465f-5c98-46ef-b815-1ee1682f6752'

// queryParams is used for passing to report card
export default function ReportCard({queryParams, show}) {
  const params = new URLSearchParams(queryParams)

  return (
    <div style={{
      textAlign: "center",
      display: show ? "block" : "none",
      height: '100%',
      width: "100%",
    }} >
      {!!queryParams &&
        <iframe
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            margin: 0,
            padding: 0,
            display: "block",
          }}
          title="reportCard"
          src={`${href}?${params}#hide_parameters=user_name%2Cmediator_id&bordered=false`}
        >
        </iframe>
      }
    </div>
  )
}
