import React from 'react';

import {
  Avatar,
  Chip,
  Input,
  Link,
  Select,
  MenuItem,
  TextField,
  Icon,
  Badge,
  Tooltip,
  Autocomplete,
  FormHelperText,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import {
  connectProps,
  TemplateConnector,
} from '@devexpress/dx-react-core'
import {
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import { Link as RouterLink } from "react-router-dom";

import { resizeImage } from './ImageResizer';
import { formatDate, parseDate } from './formatter'
import { selectValueLookup } from './utils'
import { validate } from './validationUtils'
import SelectEditor from './SelectEditor'
import { ImageUploader } from './ImageUploader'


const multiValueLookup = (availableColumnValues, value) => {
  if (!value) return []
  const ids = "edges" in value ? value.edges.map(e => e.node.id) : value.map(v => v.id)
  return availableColumnValues.filter((v) => ids.includes(v.id))
}

const formatNamedValue = (v) => ( v.type ? v.name + ' ' +  v.type : v.name)

const MultiFormatter = ({ column, availableValues, value }) => {
  const selected = multiValueLookup(availableValues[column.name], value)

  function formatValueObj (v) {
    const label = formatNamedValue(v)
    return <Chip label={label} key={label} />
  }
  return selected.map(formatValueObj)
}

function wrapMultiSelectChangeEvent(event, newVal, name, isFilterEditor) {
  const wrapped = { target: { name } }
  wrapped.target.value = newVal.map(v => {
    const selected = {id: v.id}
    if (isFilterEditor)
      selected['name'] = v.name
    return selected
  })
  return wrapped
}

// onValueChange takes the value, onChange takes the event. Whichever is defined is triggered
const MultiEditorInner = ({
  column, availableValues, value, onValueChange, onChange,
  isInGrid, row, disabled, readOnly, required, initialValidate,
  rowChanges, editingRowIds,
}) => {
  const isFilterEditor = !row && isInGrid
  const availableColumnValues = availableValues[column.name]
  const selected = multiValueLookup(availableColumnValues, value)

  const error = validate({
    isFilterEditor, value, column, required, initialValidate,
    rowChanges, editingRowIds,
  })

  const onChangeHandler = React.useCallback((event, newVal) => {
    const wrappedEvent = wrapMultiSelectChangeEvent(event, newVal, column.name, isFilterEditor)
    return onChange ? onChange(wrappedEvent) : onValueChange(wrappedEvent.target.value)
  }, [column, onChange, onValueChange, isFilterEditor])

  if (readOnly || disabled) {
    return (
      <TextField
        variant="standard"
        label={isInGrid ? "" : column.title}
        name={column.name}
        required={required && !isFilterEditor}
        value={selected.map(formatNamedValue).join(', ')}
        disabled={disabled}
        InputProps={{
          readOnly: readOnly,
        }} />
    );
  }

  return (
    <Autocomplete
      multiple
      autoHighlight
      options={availableColumnValues}
      getOptionLabel={formatNamedValue}
      value={selected}
      onChange={onChangeHandler}
      style={{ width: isFilterEditor ? '95%' : '100' }}
      size="small"
      renderInput={(params) => {
        // https://github.com/mui-org/material-ui/pull/18376#issuecomment-558394576
        return (
          <form noValidate autoComplete="off">
            <TextField
              {...params}
              variant="standard"
              label={isInGrid ? "" : column.title}
              name={column.name}
              required={required && !isFilterEditor}
              placeholder={isFilterEditor ? 'Filter...' : ''}
              size="small"
              error={!!error}
              helperText={error}
            />
          </form>
        )
      }}
    />
  )
}

const MultiEditor = (props) => {
  return (
    <TemplateConnector>
      {(getters, actions) => (
        <MultiEditorInner {...props} {...getters} {...actions} />
      )}
    </TemplateConnector>
  )
}

const MultiTypeProvider = props => {
  const { availableValues } = props
  const { Editor, Formatter } = React.useMemo(() => ({
    Editor: connectProps(MultiEditor, () => ({ availableValues, isInGrid: true })),
    Formatter: connectProps(MultiFormatter, () => ({ availableValues }))
  }), [availableValues])

  return (
    <DataTypeProvider
      formatterComponent={Formatter}
      editorComponent={Editor}
      {...props}
    />
  )
}

function getLink(column, valueObj) {
  const href = `/${column.dataName || column.name}/${valueObj.id}`
  return <Link component={RouterLink} to={href} underline="hover">{valueObj.name}</Link>;
}

const SelectFormatter = ({ column, availableValues, value }) => {
  const availableColumnValues = availableValues[column.name]
  const valueObj = selectValueLookup(availableColumnValues, value)
  if (valueObj?.name) {
    return column.linkable && valueObj.id ? getLink(column, valueObj) : valueObj.name
  }
  return ""
}

const SelectTypeProvider = props => {
  const { availableValues } = props
  const { Editor, Formatter } = React.useMemo(() => ({
    Editor: connectProps(SelectEditor, () => ({ availableValues, isInGrid: true })),
    Formatter: connectProps(SelectFormatter, () => ({ availableValues }))
  }), [availableValues])

  return (
    <DataTypeProvider
      formatterComponent={Formatter}
      editorComponent={Editor}
      {...props}
    />
  )
}

const NumberFormatter = ({ value }) => {
  if (!value && value !== 0) return ''

  return value
}

function wrapNumberChangeEvent(event) {
  return { target: { name: event.target.name, value: parseFloat(event.target.value) } }
}

const NumberEditorInner = ({
  column, value, onValueChange, onChange,
  isInGrid, row, readOnly, required, initialValidate,
  rowChanges, editingRowIds,
}) => {
  const isFilterEditor = !row && isInGrid

  const error = validate({
    isFilterEditor, value, column, required, initialValidate,
    rowChanges, editingRowIds,
  })

  const onChangeHandler = React.useCallback(event => {
    const wrappedEvent = wrapNumberChangeEvent(event)
    return onChange ? onChange(wrappedEvent) : onValueChange(wrappedEvent.target.value)
  } , [onChange, onValueChange])

  return (
    <TextField
      variant="standard"
      type="number"
      name={column.name}
      label={isInGrid ? "" : column.title}
      value={value || ''}
      onChange={onChangeHandler}
      required={required && !isFilterEditor}
      style={{ width: isFilterEditor ? '95%' : '100' }}
      placeholder={isFilterEditor ? 'Filter...' : ''}
      error={!!error}
      helperText={error}
      InputProps={{
        readOnly: readOnly,
      }} />
  );
}

const NumberEditor = (props) => {
  return (
    <TemplateConnector>
      {(getters, actions) => (
        <NumberEditorInner {...props} {...getters} {...actions} />
      )}
    </TemplateConnector>
  )
}

const NumberTypeProvider = props => {
  const Editor = React.useMemo(() => (
    connectProps(NumberEditor, () => ({ isInGrid: true }))
  ), [])

  return (
    <DataTypeProvider
      formatterComponent={NumberFormatter}
      editorComponent={Editor}
      {...props}
    />
  )
}

const TextEditorInner = ({
  column, row, value, onValueChange, onChange, isInGrid, autoFocus, readOnly,
  required, initialValidate, rowChanges, editingRowIds,
}) => {
  const isFilterEditor = !row && isInGrid
  const error = validate({
    isFilterEditor, value, column, required, initialValidate,
    rowChanges, editingRowIds
  })

  const onChangeHandler = React.useCallback(event => {
    return onChange ? onChange(event) : onValueChange(event.target.value)
  } , [onChange, onValueChange])

  return (
    <TextField
      variant="standard"
      name={column.name}
      label={isInGrid ? "" : column.title}
      value={value || ''}
      onChange={onChangeHandler}
      required={required && !isFilterEditor}
      style={{ width: isFilterEditor ? '95%' : '100' }}
      placeholder={isFilterEditor ? 'Filter...' : ''}
      error={!!error}
      helperText={error}
      InputProps={{
        readOnly: readOnly,
      }}
      autoFocus={autoFocus} />
  );
}

const TextEditor = (props) => {
  return (
    <TemplateConnector>
      {(getters, actions) => (
        <TextEditorInner {...props} {...getters} {...actions} />
      )}
    </TemplateConnector>
  )
}

const TextTypeProvider = props => {
  const Editor = React.useMemo(() => (
    connectProps(TextEditor, () => ({ isInGrid: true }))
  ), [])
  return (
    <DataTypeProvider
      editorComponent={Editor}
      {...props}
    />
  )
}

const DateEditorInner = ({
  column, value, onValueChange, onChange,
  isInGrid, row, disabled, readOnly, required, initialValidate,
  rowChanges, editingRowIds,
}) => {
  const isFilterEditor = !row && isInGrid

  const error = validate({
    isFilterEditor, value, column, required, initialValidate,
    rowChanges, editingRowIds,
  })

  const onChangeHandler = React.useCallback(event => {
    return onChange ? onChange(event) : onValueChange(event.target.value)
  } , [onChange, onValueChange])

  const today = new Date().toISOString().split("T")[0]

  return (
   <TextField
     variant="standard"
     type="date"
     name={column.name}
     label={isInGrid ? "" : column.title}
     value={value || ''}
     onChange={onChangeHandler}
     required={required}
     InputLabelProps={{ shrink: true }}
     inputProps={{
       max: column.max || today,
       min: column.min,
       readOnly,
     }}
     error={!!error}
     helperText={error}
     disabled={disabled} />
  );
}

const DateEditor = (props) => {
  return (
    <TemplateConnector>
      {(getters, actions) => (
        <DateEditorInner {...props} {...getters} {...actions} />
      )}
    </TemplateConnector>
  )
}

const DateFormatter = ({ value }) => {
  if (!value) return '';
  return formatDate(parseDate(value, 'yyyy-MM-dd'))
}

const DateTypeProvider = props => {
  const Editor = React.useMemo(() => (
    connectProps(DateEditor, () => ({ isInGrid: true }))
  ), [])

  return (
    <DataTypeProvider
      editorComponent={Editor}
      formatterComponent={DateFormatter}
      {...props}
    />
  )
}

const ImageFormatter = ({ value }) => {
  const PREFIX = 'ImageFormatter';
  const classes = {
    smallAvatar: `${PREFIX}-smallAvatar`,
  }
  const Root = styled(Avatar)(({ theme }) => ({
    [`&.${classes.smallAvatar}`]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: "-10px auto",
    },
  }))

  if (!value) return ''

  const src = value instanceof File ? URL.createObjectURL(value) : value.url

  return (
    <Root src={src} alt="Avatar" className={classes.smallAvatar} />
  )
}


const ImageEditor =  ({
  column, value, onValueChange, onChange, isInGrid, readOnly,
}) => {
  const onChangeHandler = React.useCallback(async file => {
    if (!file) return

    const resizedFile = await resizeImage(file)
    const event = { target: { name: column.name, value: resizedFile} }
    return onChange ? onChange(event) : onValueChange(event.target.value)
  } , [onChange, onValueChange, column.name])

  const PREFIX = 'ImageEditor';
  const classes = {
    root: `${PREFIX}-largeAvatar`,
  }
  const Root = styled(Avatar)(({ theme }) => ({
    [`&.${classes.largeAvatar}`]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
  }))

  if (isInGrid || (readOnly && !value)) return ''

  if (readOnly && value)
    return (
      <Root
        src={value.url}
        className={classes.largeAvatar}
        alt="Avatar"
      />
    )

  const initialUrl = value instanceof File ? URL.createObjectURL(value) : value?.url
  return (
    <ImageUploader onChange={onChangeHandler} initialUrl={initialUrl}/>
  )
}

const ImageTypeProvider = props => {
  const { Editor, Formatter } = React.useMemo(() => ({
    Editor: connectProps(ImageEditor, () => ({ isInGrid: true })),
    Formatter: connectProps(ImageFormatter, () => ({ isInGrid: true } ))
  }), [])

  return (
    <DataTypeProvider
      formatterComponent={Formatter}
      editorComponent={Editor}
      {...props}
    />
  )
}

const BooleanFormatter = ({ value }) => {
  if (value === null || value === undefined)
    return ''

  return value ? 'Yes' : 'No'
}

const BooleanEditorInner = ({
  column, value, onValueChange, onChange,
  isInGrid, row, disabled, readOnly, required,
  initialValidate, rowChanges, editingRowIds,
}) => {
  const isFilterEditor = !row && isInGrid
  const defaultValue =  isFilterEditor ? "SelectFilterAny" : ""

  const error = validate({
    isFilterEditor, value, column, required, initialValidate,
    rowChanges, editingRowIds,
  })

  const [selectedValue, setValue] = React.useState(BooleanFormatter({value}));
  React.useEffect(() => {
    const formatedVal = BooleanFormatter({value})
    setValue(formatedVal ? formatedVal : defaultValue)
  }, [value, defaultValue])

  const onChangeHandler = React.useCallback(event => {
    let value;
    switch(event.target.value) {
      case 'Yes':
        value = true
        break
      case 'No':
        value = false
        break
      default:
        value = null
    }
    const wrappedEvent = {target: {value: value, name: column.name}}
    return onChange? onChange(wrappedEvent) : onValueChange(value)
  } , [column, onChange, onValueChange])

  const innerSelect = (
    <Select
      variant="standard"
      value={selectedValue}
      onChange={onChangeHandler}
      style={{ width: isFilterEditor ? '95%' : '100' }}
      input={<Input
               name={column.name}
               required={required && !isFilterEditor}
               disabled={disabled}
               readOnly={readOnly}
             />}>
      {isFilterEditor &&
        <MenuItem key="none" value="SelectFilterAny">
          Any
        </MenuItem>
      }
      <MenuItem key="yes" value="Yes">
        Yes
      </MenuItem>
      <MenuItem key="no" value="No">
        No
      </MenuItem>
    </Select>
  )

  if(isInGrid)
    return innerSelect

  return (
    <FormControl variant="standard" margin="normal" error={!!error}>
      <InputLabel required={column.required}>{column.title}</InputLabel>
      { innerSelect }
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}

const BooleanEditor = (props) => {
  return (
    <TemplateConnector>
      {(getters, actions) => (
        <BooleanEditorInner {...props} {...getters} {...actions} />
      )}
    </TemplateConnector>
  )
}

const BooleanTypeProvider = props => {
  const { Editor } = React.useMemo(() => ({
    Editor: connectProps(BooleanEditor, () => ({ isInGrid: true })),
  }), [])

  return (
    <DataTypeProvider
      formatterComponent={BooleanFormatter}
      editorComponent={Editor}
      {...props}
    />
  )
}


const PercentageFormatter = ({ value }) => {
  if (!value && value !== 0) {
    return '-'
  }
  return '' + (value * 100.0).toFixed(2) + '%'
}

const PercentageTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={PercentageFormatter}
    {...props}
  />
)


const NotificationFormatter = ({ value }) => {
  const count = value?.length
  if (!count)
    return (
    <Tooltip
      title="Data check passed"
      placement='top'
      arrow>
      <Icon className="material-icons-outlined" color="primary">task_alt</Icon>
    </Tooltip>
    )

  const notifications = value.join('\n')

  return (
    <Tooltip
      title={
        <div style={{ whiteSpace: 'pre-line' }}>{notifications}</div>
      }
      placement='top'
      arrow>

      <Badge badgeContent={count} color='error'>
        <NotificationImportantIcon />
      </Badge>

    </Tooltip>
  )
}

const NotificationEditor = () => <span />;

const NotificationTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NotificationFormatter}
    editorComponent={NotificationEditor}
    {...props}
  />
)

const CTSCaseLinkFormatter = ({ value, row }) => {
  if (!row.ctsCaseId) return value

  return (
    <Link
      href={`https://cts.court.go.ke/index.php/case_details/view_case_details/${row.ctsCaseId}`}
      underline="hover"
      color="blue"
      target="_blank"
    > {value}</Link>
  )
}

const CTSCaseSearchTypeProvider = props => {
  return (
    <DataTypeProvider
      formatterComponent={CTSCaseLinkFormatter}
      {...props}
    />
  )
}

export {
  SelectTypeProvider,
  MultiTypeProvider,
  NumberTypeProvider,
  TextTypeProvider,
  DateTypeProvider,
  ImageTypeProvider,
  BooleanTypeProvider,
  PercentageTypeProvider,
  NotificationTypeProvider,
  CTSCaseSearchTypeProvider,
  MultiEditor,
  NumberEditor,
  TextEditor,
  DateEditor,
  ImageEditor,
  BooleanEditor,
}
