import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Input, InputAdornment } from '@mui/material';
import Search from '@mui/icons-material/Search';
import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from "@devexpress/dx-react-core";

import debounce from 'lodash.debounce'

const PREFIX = 'SearchPanel';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPlugin = styled(Plugin)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.action.active,
  }
}));


const SearchPanelInputBase = ({
  onValueChange, value, ...restProps
}) => {
  const [searchValue, setSearchValue] = React.useState(value)
  const debouncedOnValueChange = React.useCallback(debounce(onValueChange, 300), [])
  const onChange = (searchVal) => {
    setSearchValue(searchVal)
    debouncedOnValueChange(searchVal)
  };

  return (
    <Input
      onChange={e => onChange(e.target.value)}
      value={searchValue}
      type="text"
      placeholder="Search..."
      {...restProps}
      startAdornment={(
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      )}
    />
  )
}

const SearchPanelInput = SearchPanelInputBase;

const pluginDependencies = [{ name: "Toolbar" }, { name: "SearchState" }];

const SearchPanel = () => {
  return (
    <StyledPlugin name="SearchPanel" dependencies={pluginDependencies}>
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {({ searchValue }, { changeSearchValue }) => (
            <SearchPanelInput
              value={searchValue}
              onValueChange={changeSearchValue}
            />
          )}
        </TemplateConnector>
      </Template>
    </StyledPlugin>
  );
}

export default SearchPanel
