import React from 'react';
import {
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import Search from '@mui/icons-material/Search'
import { validate } from './validationUtils'
import {
  TemplateConnector,
} from '@devexpress/dx-react-core'
import {
  CTS_CASE_SEARCH_QUERY,
} from './queries'
import { useLazyQuery } from '@apollo/client';
import { parseCitation } from './utils'

const CTSCaseSearchEditorInner = ({
  column, row, value, onValueChange, onChange, isInGrid, readOnly,
  required, initialValidate, rowChanges, editingRowIds,
}) => {
  const [open, setOpen] = React.useState(false);
  const [autocompleteValue, setAutocompleteValue] = React.useState({});
  const isFilterEditor = !row && isInGrid
  const [searchError, setSearchError] = React.useState('');
  const [noMatch, setNoMatch] = React.useState('');
  const [helperText, setHelperText] = React.useState('');

  const [
    searchCTSCases,
    { loading, error, data }
  ] = useLazyQuery(CTS_CASE_SEARCH_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // console.log('searchCTSCases onCompleted data', data)
      if (data?.ctsCaseSearch?.length) {
        setNoMatch('')
        setOpen(true)
      } else {
        setNoMatch('No matching case found in CTS. Please check Court Type and Original Case Number.')
      }
    }
  })

  const validationError = validate({
    isFilterEditor, value, column, required, initialValidate,
    rowChanges, editingRowIds
  }) || (!row.ctsCaseId && initialValidate ? 'Please search and select the corresponding CTS case' : '')

  const onChangeHandler = React.useCallback((event, newVal) => {
    if (!event && (!newVal || typeof newVal == 'string')) return
    const ctsCaseNumber = event?.target?.value ? event.target.value : newVal.ctsCaseNumber
    const ctsCaseId = newVal ? newVal.ctsCaseId : row.ctsCaseId
    if (onChange) {
      onChange({ target: { name: column.name, value: ctsCaseNumber } })
      onChange({ target: { name: 'ctsCaseId', value: ctsCaseId } })
      if (newVal?.citation) {
        const [plaintiff, defendant, deceased] = parseCitation(newVal.citation)
        onChange({ target: { name: 'plaintiffName', value: plaintiff } })
        onChange({ target: { name: 'defendantName', value: defendant } })
        onChange({ target: { name: 'deceasedName', value: deceased } })
      }
    } else {
      onValueChange(ctsCaseNumber)
      onValueChange(ctsCaseId)
    }
  } , [onChange, onValueChange, column.name, row.ctsCaseId])

  const onOptionSelected = React.useCallback((event, newVal) => {
    setAutocompleteValue(newVal)
    onChangeHandler(null, newVal)
  } , [onChangeHandler])

  const handleCTSCaseLookup = React.useCallback(() => {
    // console.log('handleCTSCaseLookup start')
    const missing = []
    if (!row?.courtStation || !row?.courtStation?.id) missing.push('Court Station')
    if (!row?.courtType || !row?.courtType?.id) missing.push('Court Type')
    if (!row?.originalCaseNumber) missing.push('Original Case No.')

    if (missing.length > 0) {
      setSearchError(missing.join(', ') + ' must be present to search CTS')
      return
    }
    setSearchError('')

    // Close the option list while loading
    setOpen(false)
    const variables = {
      courtStationId: row.courtStation?.id,
      courtTypeId: row.courtType?.id,
      originalCaseNumber: row.originalCaseNumber,
    }
    // console.log('handleCTSCaseLookup passed all checks, sending with params:', variables)
    searchCTSCases({ variables })
    // console.log('handleCTSCaseLookup request sent')
  }, [searchCTSCases, row?.courtStation, row?.courtType, row?.originalCaseNumber])

  React.useEffect(() => {
    var msg = searchError || error?.message || validationError || noMatch
    if (msg)
      msg = msg.replace('GraphQL error: ', '')
    setHelperText(loading ? '' : msg)
  }, [searchError, error, validationError, noMatch, loading])

  return (
    <Autocomplete
      freeSolo
      options={data?.ctsCaseSearch ? data.ctsCaseSearch : []}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.ctsCaseNumber === value}
      filterOptions={(x) => x}
      value={autocompleteValue}
      onChange={onOptionSelected}
      inputValue={value || ''}
      onInputChange={onChangeHandler}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      style={{ width: isFilterEditor ? '95%' : '100' }}
      getOptionLabel={(option) => option?.ctsCaseNumber ? option.ctsCaseNumber : ''}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText
            primary={option.ctsCaseNumber}
            secondary={
              <React.Fragment>
                <Typography variant="body2">{option.ctsCaseType}</Typography>
                <Typography variant="body2">{option.citation}</Typography>
              </React.Fragment>
            }
            secondaryTypographyProps={{component: 'div'}}
          />
        </ListItem>
      )}
      renderInput={(params) =>{
        // https://github.com/mui-org/material-ui/pull/18376#issuecomment-558394576
        // extra dummy input is to prevent enter to submit form, see
        // https://stackoverflow.com/a/12123196/429288
        return (
          <form noValidate autoComplete="off">
            <input type="text" style={{display: "none"}} />
            <TextField
              {...params}
              variant="standard"
              name={column.name}
              label={isInGrid ? "" : column.title}
              required={required && !isFilterEditor}
              style={{ width: isFilterEditor ? '95%' : '100' }}
              placeholder={isFilterEditor ? 'Filter...' : ''}
              error={!!helperText}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                style: { paddingRight: 0 },
                readOnly: readOnly || loading,
                endAdornment: (
                  <InputAdornment position="end">
                    {loading ? <CircularProgress color="inherit" size={20} /> :
                      (<IconButton aria-label="lookup CTS case" onClick={handleCTSCaseLookup}>
                        <Search />
                      </IconButton>)
                    }
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )
      }}
    />
  );
}

const CTSCaseSearchEditor = (props) => {
  return (
    <TemplateConnector>
      {(getters, actions) => (
        <CTSCaseSearchEditorInner {...props} {...getters} {...actions} />
      )}
    </TemplateConnector>
  )
}
export {
  CTSCaseSearchEditor,
}
