import React  from 'react';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  Popover,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'FilterChooser';

const classes = {
  root: `${PREFIX}-root`,
  popover: `${PREFIX}-popover`
};

const StyledPlugin = styled(Plugin)(( { theme }) => ({
  [`& .${classes.root}`]: {
    margin: "auto 12px"
  },

  [`& .${classes.popover}`]: {
    '& .MuiPopover-paper': {
      minWidth: "240px",
      maxWidth: "300px",
      padding: "15px",
      '& #clear-all-chip': {
        display: "block",
        marginBottom: "15px",
        opacity: "54%",
      },
      '& .MuiChip-root': {
        marginBottom: "5px"
      }
    },
  }
}));

function getChooserMsg(num){
  if (!num) {
    return 'NO FILTER'
  } else if (num === 1) {
    return '1 FILTER'
  } else {
    return num + ' FILTERS'
  }
}

function lookupColumnTitle(columns, name) {
  return columns.find(c => c.name === name).title
}

function getFilterExpression(columns, filter) {
  var filterValue = filter.value
  if (Array.isArray(filterValue)) {
    filterValue = filterValue.map(v => v.name).join(',')
  } else if (filterValue?.name) {
    filterValue = filterValue?.name
  }
  return lookupColumnTitle(columns, filter.columnName) + '=' + filterValue
}

const FilterChooser = ({filters, setFilters, columns}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (filters.length)
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = React.useMemo(() => (
    Boolean(anchorEl)
  ), [anchorEl])

  const clearAllFilters = () => {
    setFilters([])
    handleClose()
  }

  const removeFilter = React.useCallback((filter) => () => {
    const newFilters = filters.filter(f => (f.columnName !== filter.columnName))
    if (newFilters.length < 1)
      handleClose()
    setFilters(newFilters)
  }, [filters, setFilters])

  return (
    <StyledPlugin>
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <Button
        className={classes.root}
        startIcon={<FilterListIcon />}
        onClick={handleClick}
      >
        {getChooserMsg(filters.length)}
      </Button>
      <Popover
          id='filter-chooser-popover'
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.popover}
        >
        <div id='clear-all-chip'>
          <Chip
            color="secondary"
            label="Clear All Filters"
            onDelete={clearAllFilters}
          />
        </div>
        {filters.map((f) => (
          <Chip
            key={f.columnName}
            label={getFilterExpression(columns, f)}
            onDelete={removeFilter(f)}
          />
        ))}
      </Popover>
    </Template>
  </StyledPlugin>
  );
}

export default FilterChooser
