// Widget draft: https://codesandbox.io/s/withered-wood-npk8d1?file=/src/ImageUploader.js

import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Avatar } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import pdfToImageFile from "./pdfToImageFile";
import useScript from "./CustomHooks";

const PREFIX = 'ImageUploader';

const classes = {
  container: `${PREFIX}-container`,
  placeholder: `${PREFIX}-placeholder`,
  camera: `${PREFIX}-camera`,
  clear: `${PREFIX}-clear`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    position: "relative"
  },

  [`& .${classes.placeholder}`]: {
    fontSize: 40
  },

  [`& .${classes.camera}`]: {
    backgroundColor: "white",
    color: "#666",
    boxShadow: "0 0 5px grey",
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    marginTop: -30,
    marginLeft: "70px"
  },

  [`& .${classes.clear}`]: {
    backgroundColor: "white",
    borderRadius: 15,
    boxShadow: "0 0 5px grey",
    position: "absolute",
    top: "2px",
    left: "2px"
  }
}));

const baseStyle = {
  height: "100px",
  width: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 100,
  border: "2px solid #eee",
  color: "#666",
  transition: "border .24s ease-in-out"
};

const focusedStyle = {
  border: "2px solid #2196f3"
};

const acceptStyle = {
  border: "2px solid #00e676"
};

const rejectStyle = {
  border: "2px dashed #ff1744"
};

function getPreviewStyle(file) {
  if (!file) {
    return { backgroundColor: "#eee" };
  }

  return {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${file})`
  };
}

export function ImageUploader({ onChange, initialUrl }) {
  const [file, setFile] = useState(initialUrl);

  // load pdfjs asynchronously on-demand as it would have doubled the bundle size otherwise
  const scriptStatus = useScript(
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.min.js"
  );
  useEffect(() => {
    if (scriptStatus === "ready") {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js";
    }
  }, [scriptStatus]);

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: "image/*, application/pdf",
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      let fileObj = acceptedFiles?.[0];
      let fileURL = fileObj ? URL.createObjectURL(fileObj) : null;
      if (fileObj) {
        if (fileObj.type === "application/pdf") {
          fileObj = await pdfToImageFile(fileURL, fileObj.name);
          fileURL = URL.createObjectURL(fileObj);
        }
        setFile(fileURL);
        if (onChange) onChange(fileObj);
      }
    }
  });

  const clearFile = useCallback(() => {
    setFile(null);
    if (onChange) onChange(null);
  }, [onChange]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...getPreviewStyle(file)
    }),
    [isFocused, isDragAccept, isDragReject, file]
  );



  return (
    <Root className={classes.container}>
      {file && (
        <ClearIcon
          className={classes.clear}
          fontSize="small"
          onClick={clearFile}
        />
      )}
      <div {...getRootProps({ style })}>
        {scriptStatus === "ready" && <input {...getInputProps()} />}
        {!file && (
          <Icon
            className={`${classes.placeholder} material-icons-outlined md-48`}
          >
            account_circle
          </Icon>
        )}
      </div>
      <Avatar className={classes.camera} onClick={open}>
        <Icon className="material-icons" fontSize="small">
          photo_camera
        </Icon>
      </Avatar>
    </Root>
  );
}

