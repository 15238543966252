import './App.css';
import Mediator from './Mediator';
import Case from './Case';
import Officer from './Officer';
import NamedGrid from './NamedGrid';
import Spinner from './Spinner';

import React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Toolbar,
  Avatar,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from '@mui/material';
import { useAuth, canViewCases, canViewUsers, canAddMediator } from './auth'
import {
  useHistory,
  Link as RouterLink,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from './Dashboard';
import { useQuery } from '@apollo/client';
import { getMetadataFetchQuery } from './queries';

const PREFIX = 'App';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  tab: `${PREFIX}-tab`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "Work Sans",
    color: theme.palette.secondary.main,
  },

  [`& .${classes.tab}`]: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    opacity: 1,
    '&:hover': {
      background: theme.palette.primary.dark,
      borderRadius: "8px 8px 0 0",
    },
    "&.Mui-selected": {
      opacity: "1!important",
      background: 'white!important',
      color: 'black',
      borderRadius: "8px 8px 0 0",
    },
  }
}));

const AVATAR_COLORS = [ '#FF9900', '#FF49F3', '#47CDFF' ]

// https://stackoverflow.com/a/17087158/429288
function hashStr(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    var charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
}

const getAvatarColor = (name) => (
  AVATAR_COLORS[hashStr(name) % AVATAR_COLORS.length]
)

export default function App({setMsgObj, client}) {
  let auth = useAuth()
  let userData = React.useMemo(() => (
    auth.user
  ), [auth])

  let history = useHistory()


  const submitLogout = React.useCallback(async () => {
    await auth.signout(client)
    history.push('/login')
  }, [client, auth, history])

  const showCases = React.useMemo(() => (
    canViewCases(userData.permissions)
  ), [userData.permissions])

  const showOfficers = React.useMemo(() => (
    canViewUsers(userData.permissions)
  ), [userData.permissions])

  const isMediatorEditor = React.useMemo(() => (
    canAddMediator(userData.permissions)
  ), [userData.permissions])

  const defaultTab = React.useMemo(() => (
    (showCases & !isMediatorEditor) ? '/cases' : '/mediators'
  ), [showCases, isMediatorEditor])

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'user-profile-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={submitLogout}>Logout</MenuItem>
    </Menu>
  );

  const tabs = ['Mediators', 'Court Stations',
    'Accreditation Categories', 'Case Types', ]

  if (showCases) {
    tabs.splice(1, 0, "Cases")
  }
  if (showOfficers) {
    tabs.push("Officers")
  }

  const tabPathNames = tabs.map(field => (
    field[0].toLowerCase() + field.slice(1).replace(' ', '')
  ))

  const getValue = React.useCallback((location) => {
    const currentPathName = location.pathname.split('/')[1]
    if (tabPathNames.includes(currentPathName)) {
      return currentPathName
    } else { // dashboard view
      return tabPathNames.find(name => currentPathName.includes(name))
    }
  }, [tabPathNames]);

  const avatarColor = React.useMemo(() => (
    getAvatarColor(userData.user.name)
  ), [userData.user.name])

  const metadataFetchQuery = React.useMemo(() => (
    getMetadataFetchQuery(userData.permissions)
  ), [userData.permissions])

  const { data } = useQuery(metadataFetchQuery, {
    fetchPolicy: "cache-and-network",
    pollInterval: 24 * 60 * 60 * 1000, // millisec
  })

  if (!data) {
    return <Spinner/>
  }

  return (
    <Root className={classes.root}>
    <Router>
      <Route exact path="/">
        <Redirect to={defaultTab} />
      </Route>
      <Route
        path="/"
        render={(history) => (
          <AppBar position="static" elevation={0}>
            <Toolbar>
              <Grid container spacing={1}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} container alignItems="center">
                  <Typography component="h1" variant="h4" className={classes.title}>
                    Cadaster
                  </Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
                  <Link
                    href='https://www.iubenda.com/privacy-policy/87064131/full-legal'
                    target="_blank"
                    rel="noreferrer"
                    color="inherit"
                    underline="hover">
                    Privacy Policy
                  </Link>
                  <IconButton
                    id={menuId}
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                  >
                    <Avatar style={{
                      color: 'white',
                      backgroundColor: avatarColor,
                    }}>{userData.user.name[0]}</Avatar>
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
            { getValue(history.location) &&
            <Tabs TabIndicatorProps={{
                    style: {
                      backgroundColor: "white",
                    }
                  }}
                  variant="scrollable"
                  scrollButtons allowScrollButtonsMobile
                  aria-label="top tabs"
                  value={ getValue(history.location) }
            >
            {tabPathNames.map((pathName, i) => {
                return (
                  <Tab
                    label={tabs[i]}
                    className={classes.tab}
                    disableRipple
                    to={'/' + pathName}
                    value={pathName}
                    key={pathName}
                    component={RouterLink}
                  />
                )
              })}
            </Tabs>
            }
          </AppBar>
        )}
      />
      {renderMenu}
      <Switch>
        {
          tabPathNames.map((pathName) => {
            if (pathName === 'mediators') {
              return (
                <Route key='mediators' path={[`/mediators/:id`, `/mediators`]}>
                  <Mediator
                    setMsgObj={setMsgObj}
                    permissions={userData.permissions}
                    name="mediator"
                    pluralName="mediators"
                    friendlyID="macNumber"
                    rawMetaData={data}
                  />
                </Route>
              )
            } else if (pathName === 'cases') {
              return (
                <Route key='cases' path={[`/cases/:id`, `/cases`]}>
                  <Case
                    setMsgObj={setMsgObj}
                    permissions={userData.permissions}
                    userCourtStations={userData.courtStations}
                    name="case"
                    pluralName="cases"
                    friendlyID="caseNumber"
                    rawMetaData={data}
                  />
                </Route>
              )
            } else if (pathName === 'officers') {
              return (
                <Route key='officers' path={[`/officers/:id`, `/officers`]}>
                  <Officer
                    setMsgObj={setMsgObj}
                    permissions={userData.permissions}
                    name="officer"
                    pluralName="officers"
                    friendlyID="name"
                    rawMetaData={data}
                  />
                </Route>
              )
            } else if (pathName === 'courtStations') {
              const extraColumns = [{name: 'code', title: 'Code'}]
              return (
                <Route key={pathName} path={`/${pathName}`} >
                  <NamedGrid items={data[pathName]} extraColumns={extraColumns}/>
                </Route>
              )
            } else {
              return (
                <Route key={pathName} path={`/${pathName}`} >
                  <NamedGrid items={data[pathName]}/>
                </Route>
              )
            }
          })
        }
        <Route path="/mediatorsDashboard">
          <Dashboard
            pathName="mediators"
            href="https://kenya-mediation-dash.herokuapp.com/public/dashboard/e5039843-92e7-4a2c-a256-b5041efec277"
          />
        </Route>
        <Route path="/casesDashboard">
          <Dashboard
            pathName="cases"
            href="https://kenya-mediation-dash.herokuapp.com/public/dashboard/fcc23857-e9d6-4a29-82aa-13d1549020e0"
            queryParams={userData.courtStations ? {court_station: userData.courtStations} : null}
          />
        </Route>
        <Route path="/officersDashboard">
          <Dashboard
            pathName="officers"
            href="http://kenya-mediation-dash.herokuapp.com/public/dashboard/2a8e9e0b-bbf3-4439-916a-14b43f722159"
          />
        </Route>
      </Switch>
    </Router>
    </Root>
  );
}

