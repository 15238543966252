import { useState } from 'react';
import {
  SortingState,
  SearchState,
  IntegratedFiltering,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';

import Paper from '@mui/material/Paper';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';

const getRowId = row => row.id;
const baseColumns = [ { name: 'name', title: 'Name' } ]

export default function NamedGrid({items, extraColumns}) {
  const [sorting, setSorting] = useState([{ columnName: 'name', direction: 'asc' }])

  const columns = extraColumns ? baseColumns.concat(extraColumns) : baseColumns

  return(
  <Paper>
    <Grid rows={items} columns={columns} getRowId={getRowId} >
      <SortingState
        sorting={sorting}
        onSortingChange={setSorting}
      />
      <IntegratedSorting />
      <SearchState />
      <IntegratedFiltering />
      <VirtualTable height="80vh"/>
      <TableHeaderRow showSortingControls />
      <Toolbar />
      <SearchPanel />
    </Grid>
  </Paper>
  )
}
