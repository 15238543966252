function getPage(pdf, num, filename) {
  return new Promise((resolve) => {
    pdf.getPage(num).then(page => {
      const scale = 1.5;
      const viewport = page.getViewport({scale});
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      page.render({
        canvasContext, viewport
      }).promise.then(() => {
        canvas.toBlob(blob => {
          const imageFile = new File([blob], `${filename}.jpg`, { type: "image/jpeg" })
          resolve(imageFile)
        }, 'image/jpeg')
      })
    })
  })
}

async function pdfToImageFile(file, filename) {
  if (!file) return

  const pdf = await window.pdfjsLib.getDocument(file).promise
  if (!pdf) return

  return await getPage(pdf, 1, filename)
}

export default pdfToImageFile;
