import React from 'react';
import { gql, useMutation } from '@apollo/client';
import posthog from 'posthog-js'

const USER_KEY = "loggedInUser";
const REFRESHED_FLAG = 'refreshed'
function saveUser(tokens) {
  localStorage.setItem(USER_KEY, JSON.stringify(tokens));
  localStorage.removeItem(REFRESHED_FLAG);
}

function getUser() {
  return JSON.parse(localStorage.getItem(USER_KEY));
}

function deleteUser() {
  localStorage.removeItem(USER_KEY);
}

const LOGIN_QUERY = gql`
mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
      name
      email
    }
    permissions
    courtStations
  }
}
`

const LOGOUT_QUERY = gql`
mutation logout {
  logout {
    deleted
  }
}
`

const authContext = React.createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

function useAuth() {
  return React.useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = React.useState(getUser());

  const [login, { data }] = useMutation(LOGIN_QUERY);
  const [logout, response] = useMutation(LOGOUT_QUERY)

  const signin = async (loginDetails) => {
    try {
      const { data } = await login({ variables: loginDetails })
      if (data && data.login) {
        saveUser(data.login)
        setUser(data.login)
        const user = data.login.user
        if (posthog.__loaded && user) {
          const timestamp = new Date()
          posthog.identify(user.id, {last_login_at: timestamp})
          posthog.people.set({
            email: user.email,
            name: user.name,
            last_login_at: timestamp
          })
        }
      } else {
        console.error('Login failed', data)
      }
    } catch (e) {
      if (e.message && !e.message.includes('Please enter valid credentials')) {
        console.error(e)
      }
    }
  }

  const signout = async () => {
    setUser(null);
    deleteUser()
    await logout()
    if (posthog.__loaded) {
      posthog.reset()
    }
  };

  return {
    user,
    signin,
    signout
  };
}


function canPerform(action, name, permissions) {
  if (['mediator', 'case'].includes(name)) {
    return permissions?.includes(`data.${action}_${name}`)
  } else if (name == 'officer') {
    return permissions?.includes(`authtools.${action}_user`)
  } else {
    return false
  }
}

function canViewCases(permissions) {
  return canPerform('view', 'case', permissions)
}

function canViewUsers(permissions) {
  return canPerform('view', 'officer', permissions)
}

function canAddMediator(permissions) {
  return canPerform('add', 'mediator', permissions)
}


export {
  saveUser,
  deleteUser,
  useAuth,
  ProvideAuth,
  canViewCases,
  canViewUsers,
  canAddMediator,
  REFRESHED_FLAG,
  canPerform,
}
