import React from 'react';

import {
  Alert,
  Button,
  Chip,
  Grid,
  FormControl,
  Typography,
  Collapse,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import IconButton from '@mui/material/IconButton';
import SelectEditor from './SelectEditor'
import { useQuery, useMutation } from '@apollo/client';
import {
  DUMMY_QUERY,
  MEDIATOR_RECOMMENDATIONS_QUERY,
  REJECT_MEDIATOR_RECOMMENDATION_QUERY,
} from './queries'
import MediatorPicker from './MediatorPicker'
import MediatorAppointmentDate from './MediatorAppointmentDate'
import { SELECT_FALLBACK_VALUE, wrapSelectChangeEvent } from './utils'

const MEDIATOR_DECLINED = "Mediator declined the case"
const MEDIATOR_UNREACHABLE = "Mediator could not be reached"
const OTHER = "Other"

function getToday() {
  return new Date().toISOString().split("T")[0]
}

const MediatorRecommender = ({
  column, availableValues, value, onChange, setMsgObj,
  row, disabled, readOnly, required, setSaveButtonText,
}) => {
  const [openInfo, setOpenInfo] = React.useState(true);
  const skipQuery = !row.id || row.id < 0 || readOnly
  const [recommendation, setRecommendation] = React.useState({})
  const [showDeclineReason, setShowDeclineReason] = React.useState(false)
  const [rejectError, setRejectError] = React.useState('')
  const [rejectOtherError, setRejectOtherError] = React.useState('')
  const [recommended, setRecommended] = React.useState(false)
  const [exhaustedCleared, setExhaustedCleared] = React.useState(false)

  const rowAppointmentDate = row.mediatorAppointmentDate
  const [mediatorAppointmentDate, setMediatorAppointmentDate] = React.useState(rowAppointmentDate)

  const { loading, error, data } = useQuery(MEDIATOR_RECOMMENDATIONS_QUERY || DUMMY_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: skipQuery,
    variables: { caseId: row.id },
  })

  const rejectRefetchQueries = [{
    query: MEDIATOR_RECOMMENDATIONS_QUERY,
    variables: { caseId: row.id },
  }]
  const onRejectError = (err) => {
    const errMsg = err.graphQLErrors ? err.graphQLErrors[0].message : err.message
    setRejectError(errMsg)
  }
  const [rejectRecommendation] = useMutation(REJECT_MEDIATOR_RECOMMENDATION_QUERY, {
    onCompleted: () => setRejectError(''),
    onError: onRejectError,
    refetchQueries: rejectRefetchQueries,
  })
  const saveRejectToDB = React.useCallback(() => {
    const {id, rejectReason, rejectReasonOther, rejectReasonCourtNamedBy} = recommendation
    if (id && rejectReason) {
      rejectRecommendation({variables: {
        id, rejectReason, rejectReasonOther, rejectReasonCourtNamedBy
      }})
    }

    setShowDeclineReason(false)
  }, [recommendation, rejectRecommendation])
  const validateAndSaveRejectToDB = React.useCallback(() => {
    if (recommendation.rejectReason == 'OTHER' && !recommendation.rejectReasonOther) {
      setRejectOtherError('Required')
      return true // keep the alert box open
    }
    saveRejectToDB()
  }, [recommendation, saveRejectToDB])

  React.useEffect(() => {
    let nextRecommendation = {}
    const recommendations = data?.['mediatorRecommendations']
    if (recommendations && recommendations.length) {
      const remainingCandidates = recommendations.filter(r => !r.rejectedAt)
      nextRecommendation = remainingCandidates.length ? remainingCandidates[0] : {}
    }
    setRecommendation(nextRecommendation)
  }, [data])

  const specificMediator = ['COURT_NAMED', 'PARTIES_REQUESTED'].includes(recommendation.rejectReason)
  React.useEffect(() => {
    const wrappedEventClearValue = wrapSelectChangeEvent(column.name, null, SELECT_FALLBACK_VALUE)
    if (recommendation.mediator && !specificMediator && recommendation.mediator.id != value?.id) {
      const wrappedEvent = wrapSelectChangeEvent(column.name, recommendation.mediator, SELECT_FALLBACK_VALUE)
      onChange(wrappedEvent)
      setRecommended(true)

      setSaveButtonText(recommendation.acceptedAt ? 'Save' : 'Accept mediator')
      setMediatorAppointmentDate(recommendation.acceptedAt ? rowAppointmentDate : getToday())
    } else if (!recommendation.mediator && recommended && !exhaustedCleared) {
      // exhausted all recommendations
      onChange(wrappedEventClearValue)
      setExhaustedCleared(true)
    } else if (specificMediator && value?.id == recommendation.mediator.id) {
      // another mediator is named by parties or court
      onChange(wrappedEventClearValue)
    }
  }, [
    recommendation,
    column.name,
    onChange,
    value,
    recommended,
    specificMediator,
    setSaveButtonText,
    rowAppointmentDate,
    exhaustedCleared,
  ])

  // Clear the rejectRecommendation on the row as it is only used with MediatorPicker when a specific mediator is to be picked
  React.useEffect(() => {
    if (recommendation && !specificMediator && row.rejectRecommendation) {
      onChange({target: {name: 'rejectRecommendation', value: null}})
    }
  }, [
    recommendation,
    specificMediator,
    onChange,
    row.rejectRecommendation,
  ])

  const onDeclineReasonChange = (event) => {
    const rec = {...recommendation}
    rec.rejectReason = event.target.value
    setRecommendation(rec)
  }

  const onDeclineReasonOtherChange = (event) => {
    const rec = {...recommendation}
    rec.rejectReasonOther = event.target.value
    setRecommendation(rec)
  }

  const onPreNextMediatorAlertCancel = () => {
    const rec = {...recommendation}
    rec.rejectReason = null
    rec.rejectReasonOther = null
    setRecommendation(rec)
    setShowDeclineReason(false)
  }

  const recommendationEndAdornment = (
    <InputAdornment position="end">
      {recommendation?.acceptedAt ?
        <Chip label="Accepted" color="primary" size="small" style={{margin: '0 5px'}}/> :
        <AutoAwesomeIcon color="primary" />
      }
    </InputAdornment>
  )

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error loading mediator recommendation. Please refresh the page.</p>;

  if (!recommendation.mediator || specificMediator) return (
    <MediatorPicker
      column={column}
      availableValues={availableValues}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      row={row}
      required={required || specificMediator}
      recommendation={recommendation}
      setRecommendation={setRecommendation}
      setShowDeclineReason={setShowDeclineReason}
      setSaveButtonText={setSaveButtonText}
      setMsgObj={setMsgObj}
    />
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Smart Mediator Assignment
          <IconButton
            aria-label="info"
            color="info"
            size="small"
            onClick={() => { setOpenInfo(!openInfo) }}
          >
            <InfoOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Collapse in={openInfo}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => { setOpenInfo(false) }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          The Smart Assignment feature automatically recommends the most suitable mediator.
        </Alert>
      </Collapse>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" margin="normal" fullWidth>
          <SelectEditor
            column={column}
            availableValues={availableValues}
            value={recommendation.mediator}
            row={row}
            readOnly
            readOnlyInputProps={{endAdornment: recommendationEndAdornment}}
          />
        </FormControl>

        {showDeclineReason &&
        <FormControl variant="standard" margin="normal" fullWidth error={!!rejectError}>
          <InputLabel id="decline-reason-label">Decline Reason</InputLabel>
          <Select
            labelId="decline-reason-label"
            id="rejectReason"
            value={recommendation.rejectReason || ''}
            onChange={onDeclineReasonChange}
            label="Decline Reason"
          >
            <MenuItem value="MEDIATOR_DECLINED">{MEDIATOR_DECLINED}</MenuItem>
            <MenuItem value="MEDIATOR_UNREACHABLE">{MEDIATOR_UNREACHABLE}</MenuItem>
            <MenuItem value="PARTIES_REQUESTED">Parties requested a specific mediator</MenuItem>
            <MenuItem value="COURT_NAMED">Court named a specific mediator</MenuItem>
            <MenuItem value="OTHER">{OTHER}</MenuItem>
          </Select>
          <FormHelperText>{rejectError}</FormHelperText>
        </FormControl>

        }

        <FormControl variant="standard" margin="normal" fullWidth>
          <MediatorAppointmentDate mediatorAppointmentDate={mediatorAppointmentDate} />
        </FormControl>

        {!showDeclineReason &&
        <FormControl variant="standard" margin="normal">
          <Button variant="outlined" color="error" onClick= {() => setShowDeclineReason(true)}>
            Decline Mediator
          </Button>
        </FormControl>
        }

        {recommendation.rejectReason == 'OTHER' &&
          <PreNextMediatorAlert alertTitle={OTHER} onCancel={onPreNextMediatorAlertCancel} onNext={validateAndSaveRejectToDB}>
            <FormControl variant="standard" margin="normal" fullWidth>
              <TextField
                multiline
                variant="standard"
                name="other"
                label="Other"
                value={recommendation.rejectReasonOther || ''}
                onChange={onDeclineReasonOtherChange}
                placeholder="Detail reason for declining mediator"
                error={!!rejectOtherError}
                helperText={rejectOtherError}
                autoFocus
                required
            />
            </FormControl>
          </PreNextMediatorAlert>
        }
        {recommendation.rejectReason == 'MEDIATOR_DECLINED' &&
          <PreNextMediatorAlert alertTitle={MEDIATOR_DECLINED} onCancel={onPreNextMediatorAlertCancel} onNext={saveRejectToDB}>
            <p>
              Are you sure the mediator declined the case? A declined mediator will be blocked for 7 days from accepting new cases.
            </p>
          </PreNextMediatorAlert>
        }
        {recommendation.rejectReason == 'MEDIATOR_UNREACHABLE' &&
          <PreNextMediatorAlert alertTitle={MEDIATOR_UNREACHABLE} onCancel={onPreNextMediatorAlertCancel} onNext={saveRejectToDB}>
            <p>
              Are you sure the mediator could not be reached? A unreachable mediator will be blocked for 7 days from accepting new cases.
            </p>
          </PreNextMediatorAlert>
        }
      </Grid>
    </Grid>
  )
}

const PreNextMediatorAlert = ({onCancel, onNext, alertTitle, children}) => {
  const [open, setOpen] = React.useState(true)

  const close = () => {
    onCancel()
    setOpen(false)
  }

  const next = () => {
    const keepOpen = !!onNext()
    setOpen(keepOpen)
  }

  return (
    <Dialog open={open} onClose={close} scroll="paper" maxWidth="sm" fullWidth>
      <DialogTitle id="reject-reason-other-dialog-title">
        {alertTitle}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={next} color="primary" endIcon={<AutoAwesomeIcon />}>
          Next Mediator
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MediatorRecommender
