import React  from 'react';
import {
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import {
  TemplateConnector
} from '@devexpress/dx-react-core';
import Button from '@mui/material/Button';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function getColumnChooserMsg(numHidden){
  if (!numHidden) {
    return 'HIDE COLUMNS'
  } else if (numHidden === 1) {
    return '1 COLUMN HIDDEN'
  } else {
    return numHidden + ' COLUMNS HIDDEN'
  }
}

const ToggleButton = ({onToggle}) => {
  return (
    <TemplateConnector>
    {({ hiddenColumnNames }, ) => (
      <React.Fragment>
        <Button
          title="Show Column"
          onClick={onToggle}
          startIcon={<VisibilityOffIcon />} >
          {getColumnChooserMsg(hiddenColumnNames.length)}
        </Button>
      </React.Fragment>
    )}
    </TemplateConnector>
  );
};

const Chooser = (props) => {
  return (
    <ColumnChooser {...props} toggleButtonComponent={ToggleButton} />
  )
}

export default Chooser
