import React from 'react';

import MainGrid from './MainGrid'
import { caseColumns as columns } from './columns'
import { getAvailableValues } from './utils'
import Spinner from './Spinner';

function lookupCaseValueMode(caseValueModes, modeId) {
  const found = caseValueModes.find(({id}) => (id === modeId))
  return found?.name || ''
}

function getColumnByName(name) {
  return columns.find(col => (col.name === name))
}

const processColumnsOnData = ({
  caseValueModes, caseStatuses, caseOutcomes, caseTypes, mediators, courtTypes,
}, columns) => {
  const EXACT_OR_ESTIMATED = caseValueModes.filter(({name}) => (['Exact', 'Estimated'].includes(name)) )
                                           .map(m => m.id)
  const PENDING_STATUS = caseStatuses.find(({name}) => name === 'PENDING')
  const CONCLUDED_STATUS = caseStatuses.find(({name}) => name === 'CONCLUDED')
  const AGREEMENT_OUTCOME = caseOutcomes.find(({name}) => name === 'Settlement Agreement')
  const SUCCESSION_CASE_TYPE = caseTypes.find(({name}) => name.includes('Succession'))
  const HIGHCOURT_COURT_TYPE = courtTypes.find(({name}) => name === 'High Court')

  const lookupMediator = (row) => {
    return mediators.find(({id}) => id === row.mediator?.id)
  }

  const caseValueCol = getColumnByName('caseValue')
  caseValueCol.conditional = (row) => EXACT_OR_ESTIMATED.includes(row.caseValueMode?.id)

  const pendingReasonCol = getColumnByName('pendingReason')
  pendingReasonCol.conditional = (row) => (PENDING_STATUS.id === row.status?.id)

  const outcomeCol = getColumnByName('outcome')
  outcomeCol.conditional = (row) => (CONCLUDED_STATUS.id === row.status?.id)

  const agreementModeCol = getColumnByName('agreementMode')
  agreementModeCol.conditional = (row) => (CONCLUDED_STATUS.id === row.status?.id && AGREEMENT_OUTCOME.id === row.outcome?.id)

  const conclusionDateCol = getColumnByName('conclusionDate')
  conclusionDateCol.conditional = (row) => (CONCLUDED_STATUS.id === row.status?.id)

  const deceasedNameCol = getColumnByName('deceasedName')
  deceasedNameCol.conditional = (row) => (SUCCESSION_CASE_TYPE.id === row.caseType?.id)

  const courtDivisionCol = getColumnByName('courtDivision')
  courtDivisionCol.conditional = (row) => (HIGHCOURT_COURT_TYPE.id === row.courtType?.id)

  const valueCol = getColumnByName('value')
  valueCol.getCellValue = (row) => (
    lookupCaseValueMode(caseValueModes, row.caseValueMode?.id) + ' ' + (row.caseValue || '')
  )

  // Always hide the mediation case number field
  // as it is auto generated and available for view in the header
  const caseNumberCol = getColumnByName('caseNumber')
  caseNumberCol.conditional = () => false

  // Always hide the appointment date field
  // as it is made part of the MediatorPicker & MediatorRecommender component
  // so it can be visually positioned right after the mediator field
  const mediatorAppointmentDateCol = getColumnByName('mediatorAppointmentDate')
  mediatorAppointmentDateCol.conditional = () => false

  const mediatorPhoneCol = getColumnByName('mediatorPhone')
  mediatorPhoneCol.getCellValue = (row) => (lookupMediator(row)?.phone || '')

  const mediatorEmailCol = getColumnByName('mediatorEmail')
  mediatorEmailCol.getCellValue = (row) => (lookupMediator(row)?.user?.email || '')

  return columns
}

const getSteps = (columns) => {
  const step2 = ['mediator', 'mediatorAppointmentDate']
  const step1 = columns.map(c => c.name).filter(name => !step2.includes(name))
  return [step1, step2]
}

const editingColumnExtensions = []

const defaultHiddenColumnNames = ['caseValue', 'caseValueMode', 'ctsCaseId']

export default function Case({
  userCourtStations,
  ...props
}) {
  const processAvailableValuesOnData = React.useCallback((availableValues) => {
    if (!availableValues || !availableValues.courtStation || !userCourtStations || userCourtStations.length === 0) {
      return availableValues
    }

    const courtStation = availableValues.courtStation.filter(c => (
      userCourtStations.includes(c.name)
    ))
    return {...availableValues, courtStation}
  }, [userCourtStations])

  if (!props.rawMetaData) {
    return <Spinner />
  }

  const availableValues = processAvailableValuesOnData(getAvailableValues(props.rawMetaData, columns))
  const processedColumns = processColumnsOnData(props.rawMetaData, columns)
  const steps = getSteps(columns)

  return (
    <MainGrid
      columns={processedColumns}
      availableValues={availableValues}
      editingColumnExtensions={editingColumnExtensions}
      defaultHiddenColumnNames={defaultHiddenColumnNames}
      steps={steps}
      {...props}
    />
  )
}
