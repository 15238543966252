import DateTime from 'luxon/src/datetime.js';

const DATE_FORMAT = 'dd/MM/yyyy'
const TIMEZONE = 'Africa/Nairobi'

const formatAddress = (address) => {
  if (!address.postalCode && !address.poBoxNumber)
    return address.city || ''
  return (address.postalCode || '') + '-' + (address.poBoxNumber || '') + ' ' + (address.city || '')
}

const formatDate = (value) => {
  if (typeof value === 'string') {
    return value // assume already formatted
  }

  if (!value) {
    return null;
  }

  return value.toFormat(DATE_FORMAT)
}

const parseDate = (value, format) => {
  if (!value) {
    return null;
  }

  if (typeof value !== 'string') {
    return value // assume already parsed
  }

  if (!format) {
    format = DATE_FORMAT
  }

  return DateTime.fromFormat(value, format, { zone: TIMEZONE });
}

const formatDoB = (dob) => {
  const date = parseDate(dob, 'yyyy-MM-dd')
  if (!date) return ''
  return parseInt(-date.diffNow('years').years)
}


export {
  formatAddress,
  formatDoB,
  parseDate,
  formatDate,
  DATE_FORMAT,
}
