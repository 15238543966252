import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import {
  useLocation,
  Link as RouterLink,
} from "react-router-dom";

const PREFIX = 'ViewSwitcher';

const classes = {
  root: `${PREFIX}-root`,
  rootAbsolute: `${PREFIX}-rootAbsolute`
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginTop: "15px",
    zIndex: 1,
    position: "relative",
    "& a.MuiToggleButton-root": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "& a.MuiToggleButton-root.Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& span": {
      paddingLeft: "3px"
    },
  },

  [`&.${classes.rootAbsolute}`]: {
    position: "absolute",
    top: "10px",
    marginTop: 0,
    transform: "translate(-50%)",
  }
}));

export const ViewSwitcher = ({pathName, absolutePositioned}) => {

  const [currentView, setCurrentView] = React.useState('grid')

  let location = useLocation();
  React.useEffect(() => {
    const currentPathName = location.pathname.split('/')[1]
    if (currentPathName === `${pathName}Dashboard` && currentView !== 'dashboard') {
      setCurrentView('dashboard')
    } else if (currentPathName === pathName && currentView !== 'grid') {
      setCurrentView('grid')
    }
  }, [location, pathName, currentView]);

  let rootClass = classes.root
  if (absolutePositioned) rootClass = rootClass + ' ' + classes.rootAbsolute

  return (
    <StyledToggleButtonGroup
      value={currentView}
      exclusive
      size="small"
      className={rootClass}
    >
      <ToggleButton value="grid" component={RouterLink} to={`/${pathName}`}>
        <Icon className="material-icons-outlined" color="primary">grid_view</Icon>
        <span>GRID</span>
      </ToggleButton>
      <ToggleButton value="dashboard" component={RouterLink} to={`/${pathName}Dashboard`} >
        <Icon className="material-icons-outlined" color="primary">insights</Icon>
        <span>DASHBOARD</span>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
}
