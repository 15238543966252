import React from 'react';

import {
  Badge,
  Grid,
  Switch,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Typography,
  Collapse,
  Alert,
  Button,
} from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';

import { ASSIGNEMENT_ISSUES_QUERY } from './queries'
import { SELECT_FALLBACK_VALUE, wrapSelectChangeEvent, deepEqual } from './utils'
import SelectEditor from './SelectEditor'
import MediatorAssignmentIssues from './MediatorAssignmentIssues';
import MediatorAppointmentDate from './MediatorAppointmentDate'
import Unavailabilities from './Unavailability';

const NOT_ACTIVE_MSG = "This mediator's status is NOT ACTIVE and should not be assigned a new matter."
const DEFAULT_HEADER = 'Manual Mediator Assignment'

function getToday() {
  return new Date().toISOString().split("T")[0]
}

const getNoIssueMediatorFilter = (issueMap) => {
  return () => mediators => (
    (mediators).filter(m => !issueMap[m.id] || issueMap[m.id].length==0)
  )
}

const getActiveMediatorFilter = (issueMap) => {
  return () => mediators => (
    (mediators).filter(m => !issueMap[m.id] || !issueMap[m.id].includes(NOT_ACTIVE_MSG))
  )
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const MediatorPicker = ({
  column, availableValues, value, onChange,
  row, disabled, readOnly, required, setMsgObj,
  recommendation, setRecommendation,
  setShowDeclineReason, setSaveButtonText,
}) => {
  const [mediatorAppointmentDate, setMediatorAppointmentDate] = React.useState(row.mediatorAppointmentDate || '')
  const [lastMediator, setLastMediator] = React.useState(value)
  const [issueMap, setIssueMap] =  React.useState({})
  const defaultFiltered = !value?.id && !recommendation.rejectReason
  const [filtered, setFiltered] = React.useState(defaultFiltered)
  const [optionFilter, setOptionFilter] = React.useState(null)
  const [issues, setIssues] = React.useState([])
  const [openInfo, setOpenInfo] = React.useState(false);
  const [rejectReasonPartiesRequestedChecked, setRejectReasonPartiesRequestedChecked] = React.useState(false);
  const [rejectReasonCourtNamedByError, setRejectReasonCourtNamedByError] = React.useState(
    recommendation.rejectReason == 'COURT_NAMED' ? 'Required' : '')
  const [rejectReasonPartiesRequestedError, setRejectReasonPartiesRequestedError] = React.useState(
    recommendation.rejectReason == 'PARTIES_REQUESTED' ? 'Required' : '')

  const daysAgo30 = new Date()
  daysAgo30.setDate(daysAgo30.getDate() - 30)
  const isNewCase = (row.referralDate > daysAgo30.toISOString().split('T')[0])

  const { loading, error, data } = useQuery(ASSIGNEMENT_ISSUES_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      caseCourtStationId: row.courtStation?.id,
      caseTypeId: row.caseType?.id
    },
  })

  React.useEffect(() => {
    const assignmentIssues = data?.['mediatorAssignmentIssues'] || []
    setIssueMap(Object.fromEntries(assignmentIssues.map(i => [i.mediatorId, i.issues])))
  }, [data, isNewCase])

  React.useEffect(() => {
    setOptionFilter(
      filtered ?
      getNoIssueMediatorFilter(issueMap) :
      (isNewCase ? getActiveMediatorFilter(issueMap) : null)
      // restrict to only active mediators for cases newer than 30 days since referral
      // still allow for backfill of old case data to select non-active mediator for assignment
    )
  }, [filtered, issueMap, isNewCase])

  React.useEffect(() => {
    // hide issues if it's read-only
    setIssues(readOnly ? [] : (issueMap[value?.id] || []))
  }, [value, issueMap, readOnly, availableValues, row.referralDate])

  React.useEffect(() => {
    if (recommendation.mediator) {
      const rec = {...recommendation}
      rec.hasError = ((rec.rejectReason == 'PARTIES_REQUESTED' && rejectReasonPartiesRequestedError) ||
        (rec.rejectReason == 'COURT_NAMED' && rejectReasonCourtNamedByError) ||
        (rec.rejectReason && !value?.id))
      if (!deepEqual(row.rejectRecommendation, rec)) {
        onChange({target: {name: 'rejectRecommendation', value: rec}})
      }
    }
    setSaveButtonText('Save')
  }, [
    recommendation,
    onChange,
    rejectReasonCourtNamedByError,
    rejectReasonPartiesRequestedError,
    row.rejectRecommendation,
    value?.id,
    setSaveButtonText
  ])

  const onRejectReasonCourtNamedByChange = (event) => {
    const namedBy = event.target.value
    const rec = {...recommendation}
    rec.rejectReasonCourtNamedBy = namedBy
    setRecommendation(rec)
    setRejectReasonCourtNamedByError(!namedBy ? 'Required' : '')
  }

  const onRejectReasonPartiesRequestedChange = (event) => {
    const checked = event.target.checked
    setRejectReasonPartiesRequestedChecked(checked)
    setRejectReasonPartiesRequestedError(!checked ? 'Required' : '')
  }

  const onChangeHandler = React.useCallback((event, newVal) => {
    if (newVal?.id != value?.id) {
      setMediatorAppointmentDate(getToday())
      const wrappedEvent = wrapSelectChangeEvent(column.name, newVal, SELECT_FALLBACK_VALUE)
      return onChange(wrappedEvent)
    }
  } , [column, onChange, value])

  const clearRejectReason = React.useCallback(() => {
    const rec = {...recommendation}
    rec.rejectReason = null
    rec.rejectReasonCourtNamedBy = null
    setRecommendation(rec)
    setShowDeclineReason(false)
  }, [recommendation, setRecommendation, setShowDeclineReason])

  const handleFilter = React.useCallback((event) => {
    const checked = event.target.checked
    setFiltered(checked)

    const currentIssues = issueMap[value?.id]
    if (checked && currentIssues?.length) {
      setLastMediator(value)
      onChangeHandler(null, null)
    } else if (!checked && !value?.id) {
      onChangeHandler(null, lastMediator)
    }
  }, [issueMap, value, onChangeHandler, lastMediator])

  const renderOption = React.useCallback((props, option) => {
    return (
      <li {...props}>
        { option.name }
        { (issueMap[option?.id] && issueMap[option?.id].length > 0) &&
          (<StyledBadge badgeContent={issueMap[option?.id].length} color='error'>
             <NotificationImportantIcon />
           </StyledBadge>)
        }
      </li>
    )
    }, [issueMap])

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error loading mediator data. Please refresh the page.</p>;

  const editor = (
    <Grid item xs={12}>
      <FormControl variant="standard" margin="normal" fullWidth>
        <SelectEditor
          column={column}
          availableValues={availableValues}
          value={value}
          onChange={onChange}
          row={row}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          autocompleteParams={{
            onChange: onChangeHandler,
            renderOption: renderOption,
          }}
          optionFilter={optionFilter}
        />
      </FormControl>

      <FormControl variant="standard" margin="normal" fullWidth>
        <MediatorAppointmentDate mediatorAppointmentDate={mediatorAppointmentDate} />
      </FormControl>
    </Grid>
  )

  if (readOnly) return editor

  let header = DEFAULT_HEADER
  let additionalField = ''
  if (recommendation?.rejectReason == 'COURT_NAMED') {
    header = 'Court Named a Specific Mediator'
    additionalField = (
      <Grid item xs={12}>
        <FormControl variant="standard" margin="normal" fullWidth>
          <TextField
            variant="standard"
            name="rejectReasonCourtNamedBy"
            label="Name of the Judge/DR that appointed this mediator"
            value={recommendation.rejectReasonCourtNamedBy || ''}
            onChange={onRejectReasonCourtNamedByChange}
            error={!!rejectReasonCourtNamedByError}
            helperText={rejectReasonCourtNamedByError}
            required
        />
        </FormControl>
        <FormControl variant="standard" margin="normal">
          <Button variant="outlined" color="primary" onClick={clearRejectReason}>
            Back to Smart Assignment
          </Button>
        </FormControl>
      </Grid>
    )
  } else if (recommendation?.rejectReason == 'PARTIES_REQUESTED') {
    header = 'Parties Requested a Specific Mediator'
    additionalField = (
      <Grid item xs={12}>
        <FormControl variant="standard" margin="normal" error={!!rejectReasonPartiesRequestedError}>
          <FormControlLabel control={
            <Checkbox
              checked={rejectReasonPartiesRequestedChecked}
              onChange={onRejectReasonPartiesRequestedChange}
            />
          } label="I declare that this mediator selection represents all parties' agreement" />
          <FormHelperText>{rejectReasonPartiesRequestedError}</FormHelperText>
        </FormControl>
        <FormControl variant="standard" margin="normal">
          <Button variant="outlined" color="primary" onClick={clearRejectReason}>
            Back to Smart Assignment
          </Button>
        </FormControl>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {header}
          {header == DEFAULT_HEADER &&
            <IconButton
              aria-label="info"
              color="info"
              size="small"
              onClick={() => { setOpenInfo(!openInfo) }}
            >
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          }
        </Typography>
        <Collapse in={openInfo}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => { setOpenInfo(false) }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            style={{marginBottom: 0}}
          >
            Manual assignment allows you to choose mediators from a filtered or unfiltered list. A filtered list shows you mediators who, based on the case details and mediator profile, are eligible and available for assignment. An unfiltered list allows to select from all active mediators.
          </Alert>
        </Collapse>
      </Grid>
      <MediatorAssignmentIssues issues={issues}/>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>Filter mediator list</Grid>
          <Grid item>
            <Switch
              checked={filtered}
              onChange={handleFilter}
              name="mediatorFilter"
              color="primary"
            />
          </Grid>
        </Grid>
        {editor}
        {additionalField}
        { row.mediator &&
          <Grid item xs={12} style={{paddingTop: '16px'}}>
          <Unavailabilities
            mediatorId={ row.mediator.id }
            caseNumber={ row.caseNumber }
            setMsgObj={setMsgObj}
            refetchQueries={[{
              query: ASSIGNEMENT_ISSUES_QUERY,
              variables: {
                caseCourtStationId: row.courtStation?.id,
                caseTypeId: row.caseType?.id
              },
            }]}
          />
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default MediatorPicker
