import { formatAddress, formatDoB } from './formatter'

const MEDIATION_MIN_DATE = "2016-01-01"

const getToday = function () {
  return new Date().toISOString().split('T')[0]
}

const mediatorColumns = [
    {
      name: 'issues',
      title: 'Data Check',
      type: 'notification',
      sortName: 'issuesCount',
    },
    {
      name: 'image',
      title: 'Profile Picture',
      type: 'image',
    },
    {
      name: 'macNumber',
      title: 'MAC No.',
      type: 'text',
      required: true,
      editOnly: true,
    },
    {
      name: 'name',
      title: 'Name',
      type: 'text',
      required: true,
      sortName: 'user_Name',
      getCellValue: row => (row.user?.name),
    },
    {
      name: 'status',
      dataName: 'mediatorStatuses',
      title: 'Status',
      required: true,
      type: 'select',
    },
    {
      name: 'courtStations',
      title: 'Court Stations',
      required: true,
      type: 'multiselect',
    },
    {
      name: 'accreditationCategories',
      title: 'Accreditation Categories',
      type: 'multiselect',
      required: true,
    },
    {
      name: 'professions',
      title: 'Professions',
      type: 'multiselect',
    },
    {
      name: 'virtualMediation',
      title: 'Virtual Mediation',
      type: 'boolean',
    },
    {
      name: 'email',
      title: 'Email',
      type: 'text',
      required: true,
      sortName: 'user_Email',
      getCellValue: row => (row.user?.email),
    },
    { name: 'phone',
      title: 'Phone Number',
      type: 'text',
      validation: {
        predicate: val => (/^0[0-9]{9}( ?\/ ?0[0-9]{9})*$/.test(val)),
        errMsg: 'Phone number must be 10 digits, starting with 0. Multiple numbers can be separated by /.'
      }
    },
    {
      name: 'age',
      title: 'Age',
      getCellValue: row => (row.dateOfBirth ? formatDoB(row.dateOfBirth) : undefined),
      type: 'formulaNoFilter',
    },
    {
      name: 'dateOfBirth',
      title: 'Date of Birth',
      type: 'date',
      min: '1920-01-01',
      max: getToday(),
    },
    {
      name: 'gender',
      dataName: 'genders',
      title: 'Gender',
      type: 'select',
    },
    {
      name: 'dateOfAccreditation',
      title: 'Date of Accreditation',
      type: 'date',
      min: MEDIATION_MIN_DATE,
      max: getToday(),
    },
    {
      name: 'address',
      title: 'Address',
      type: 'formula',
      sortName: 'address_PostalCode',
      filterName: 'address',
      getCellValue: row => (row.address ? formatAddress(row.address) : undefined),
    },
    {
      name: 'postalCode',
      title: 'Postal Code',
      type: 'text',
      sortName: 'address_PostalCode',
      getCellValue: row => (row.address?.postalCode),
    },
    {
      name: 'poBoxNumber',
      title: 'PO Box Number',
      type: 'text',
      sortName: 'address_PoBoxNumber',
      getCellValue: row => (row.address?.poBoxNumber),
    },
    {
      name: 'city',
      title: 'City',
      type: 'text',
      sortName: 'address_City',
      getCellValue: row => (row.address?.city),
    },
    {
      name: 'professionalMemberships',
      title: 'Professional Memberships',
      type: 'multiselect',
    },
    {
      name: 'professionalQualifications',
      title: 'Professional Qualifications',
      type: 'multiselect',
    },
    {
      name: 'religions',
      title: 'Religions',
      type: 'multiselect',
    },
    {
      name: 'languages',
      title: 'Languages',
      type: 'multiselect',
    },
    {
      name: 'nationality',
      dataName: 'nationalities',
      title: 'Nationality',
      type: 'select',
    },
    {
      name: 'idPassportNumber',
      title: 'ID/Passport Number',
      type: 'text',
    },
    {
      name: 'numPendingCases',
      title: 'Pending Cases',
      type: 'formulaNoFilter',
      sortName: 'stats_NumPendingCases',
      getCellValue: row => row.stats?.numPendingCases,
    },
    {
      name: 'numConcludedCases',
      title: 'Concluded Cases',
      type: 'formulaNoFilter',
      sortName: 'stats_NumConcludedCases',
      getCellValue: row => row.stats?.numConcludedCases,
    },
    {
      name: 'agreementRate',
      title: 'Agreement Rate',
      type: 'percent',
      sortName: 'stats_AgreementRate',
      getCellValue: row => row.stats?.agreementRate,
    },
    {
      name: 'remark',
      title: "Remark",
      type: 'text',
    },
]

const caseColumns = [
  {
    name: 'issues',
    title: 'Data Check',
    type: 'notification',
    sortName: 'issuesCount',
  },
  {
    name: 'referralDate',
    title: 'Referral Date',
    type: 'date',
    required: true,
    min: MEDIATION_MIN_DATE,
    max: getToday(),
  },
  {
    name: 'referralMode',
    dataName: 'referralModes',
    title: 'Referral Mode',
    type: 'select',
    required: true,
  },
  {
    name: 'courtStation',
    dataName: 'courtStations',
    title: 'Court Station',
    type: 'select',
    required: true,
    createOnly: true,
  },
  {
    name: 'courtType',
    dataName: 'courtTypes',
    title: 'Court Type',
    type: 'select',
    required: true,
  },
  {
    name: 'courtDivision',
    dataName: 'courtDivisions',
    title: 'Court Division',
    type: 'select',
  },
  {
    name: 'caseType',
    dataName: 'caseTypes',
    title: 'Case Type',
    type: 'select',
    required: true,
  },
  {
    name: 'caseNumber',
    title: 'Mediation Case No.',
    type: 'text',
  },
  {
    name: 'originalCaseNumber',
    title: 'Original Case No.',
    type: 'ctsCaseLookup',
    required: true,
  },
  {
    name: 'ctsCaseId',
    title: 'CTS Case ID',
    type: 'hidden',
    required: true,
  },
  {
    name: 'caseValueMode',
    dataName: 'caseValueModes',
    title: 'Value of Subject Matter - Type',
    type: 'select',
    required: true,
  },
  {
    name: 'caseValue',
    title: 'Value of Subject Matter - Amount',
    type: 'number',
    conditionallyRequired: true,
  },
  {
    name: 'value',
    title: 'Value of Subject Matter',
    type: 'formula',
    sortName: 'caseValue',
    filterName: 'caseValueMode_Name',
  },
  {
    name: 'mediator',
    dataName: 'mediators',
    title: 'Mediator',
    type: 'select',
    editorType: 'MediatorPicker',
    sortName: 'mediator_User_Name',
    filterName: 'mediator', // need this to override sortName for filtering
  },
  {
    name: 'mediatorPhone',
    title: 'Mediator Phone',
    type: 'formula',
    sortName: 'mediator_Phone',
  },
  {
    name: 'mediatorEmail',
    title: 'Mediator Email',
    type: 'formula',
    sortName: 'mediator_User_Email',
  },
  {
    name: 'mediatorAppointmentDate',
    title: 'Date of Mediator Appointment',
    type: 'date',
    min: MEDIATION_MIN_DATE,
    max: getToday(),
  },
  {
    name: 'sessionType',
    dataName: 'sessionTypes',
    title: 'Session Type',
    type: 'select',
  },
  {
    name: 'status',
    dataName: 'caseStatuses',
    title: 'Status',
    type: 'select',
    required: true,
  },
  {
    name: 'pendingReason',
    dataName: 'casePendingReasons',
    title: 'Pending Reason',
    type: 'select',
    conditionallyRequired: true,
  },
  {
    name: 'outcome',
    dataName: 'caseOutcomes',
    title: 'Outcome',
    type: 'select',
    conditionallyRequired: true,
  },
  {
    name: 'agreementMode',
    dataName: 'agreementModes',
    title: 'Settlement Agreement',
    type: 'select',
    conditionallyRequired: true,
  },
  {
    name: 'conclusionDate',
    title: 'Date of Conclusion',
    type: 'date',
    min: MEDIATION_MIN_DATE,
    max: getToday(),
    conditionallyRequired: true,
  },
  {
    name: 'completionCertificateDate',
    title: 'Date of Completion Certificate',
    type: 'date',
    max: getToday(),
    min: MEDIATION_MIN_DATE,
  },
  {
    name: 'forwardedForPaymentDate',
    title: 'Date Forwarded for Payment',
    type: 'date',
    max: getToday(),
    min: MEDIATION_MIN_DATE,
  },
  {
    name: 'fileReturnedToCourtDate',
    title: 'Date Files Returned to Court',
    type: 'date',
    max: getToday(),
    min: MEDIATION_MIN_DATE,
  },
  {
    name: 'plaintiffName',
    title: 'Name of Plaintiffs / Applicant / Petitioner / Claimant',
    type: 'text',
  },
  {
    name: 'plaintiffContact',
    title: 'Plaintiff Contact Details',
    type: 'text',
  },
  {
    name: 'plaintiffLanguages',
    dataName: 'languages',
    title: 'Plaintiff Languages',
    type: 'multiselect',
  },
  {
    name: 'defendantName',
    title: 'Name of Defendant / Respondent',
    type: 'text',
  },
  {
    name: 'defendantContact',
    title: 'Defendant Contact Details',
    type: 'text',
  },
  {
    name: 'defendantLanguages',
    dataName: 'languages',
    title: 'Defendant Languages',
    type: 'multiselect',
  },
  {
    name: 'deceasedName',
    title: "Deceased's Name (for P&A)",
    type: 'text',
  },
  {
    name: 'remark',
    title: "Remark",
    type: 'text',
  },
]

const officerColumns = [
    {
      name: 'name',
      title: 'Name',
      type: 'text',
      required: true,
    },
    {
      name: 'email',
      title: 'Email',
      type: 'text',
      required: true,
    },
    {
      name: 'isActive',
      title: 'Active',
      type: 'boolean',
      required: true,
    },
    {
      name: 'groups',
      title: 'Access Groups',
      type: 'multiselect',
      required: true,
    },
]

export { mediatorColumns, caseColumns, officerColumns }
