import React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from '@mui/material/styles';
import { useAuth } from './auth';
import {
  Redirect,
  useLocation,
} from 'react-router-dom'

const PREFIX = 'signin';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  title: `${PREFIX}-title`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '90vh',
    flex: '0 0 auto'
  },

  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.title}`]: {
    fontWeight: "bold",
    fontFamily: "Work Sans",
    color: theme.palette.primary.main,
  }
}));

export default function SignIn() {

  let auth = useAuth();

  const [loginDetails, setLoginDetails] = React.useState({
    email: "",
    password: ""
  });


  const { state } = useLocation()

  async function submitLogin(e) {
    e.preventDefault();
    await auth.signin(loginDetails)
  }

  if (auth.user) {
    return <Redirect to={state?.from || '/'} />
  }

  return (
    <StyledContainer component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.title}>
          Cadaster
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={loginDetails.email}
            onChange={(e) => setLoginDetails({...loginDetails, email: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={loginDetails.password}
            onChange={(e) => setLoginDetails({...loginDetails, password: e.target.value})}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            LOGIN
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/accounts/password_reset/" variant="body2" underline="hover">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </StyledContainer>
  );
}
