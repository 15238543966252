import React from 'react';

import {
  TemplateConnector,
} from '@devexpress/dx-react-core'
import {
  TextField,
  Autocomplete
} from '@mui/material';
import {
  selectValueLookup,
  wrapSelectChangeEvent,
  SELECT_FALLBACK_VALUE,
} from './utils'
import { validate } from './validationUtils'

function getSelectDefaultValue(isFilterEditor) {
  return isFilterEditor ? {id: 'SelectFilterAny', name: 'Any'} : SELECT_FALLBACK_VALUE
}

function getSelectOptions({availableValues, defaultValue, column, optionFilter, isFilterEditor}) {
  let vals = availableValues[column.name]

  if (optionFilter) {
    vals = optionFilter(vals)
  }

  if (isFilterEditor) {
    vals = [defaultValue].concat(vals)
  }

  return vals
}

// onValueChange takes the value, onChange takes the event. Whichever is defined is triggered
const SelectEditorInner = ({
  column, availableValues, value, onValueChange, onChange,
  isInGrid, row, disabled, readOnly, required, autocompleteParams,
  optionFilter, initialValidate, rowChanges, editingRowIds, readOnlyInputProps,
}) => {
  const isFilterEditor = !row && isInGrid
  const defaultValue = getSelectDefaultValue(isFilterEditor)

  const error = validate({
    isFilterEditor, value, column, required, initialValidate,
    rowChanges, editingRowIds,
  })

  const onChangeHandler = React.useCallback((event, newVal, reason) => {
    if (reason === 'clear' && isFilterEditor) return
    const wrappedEvent = wrapSelectChangeEvent(column.name, newVal, defaultValue, isFilterEditor)
    return onChange? onChange(wrappedEvent) : onValueChange(wrappedEvent.target.value)
  } , [column, onChange, onValueChange, isFilterEditor, defaultValue])

  const selectOptions = getSelectOptions({availableValues, defaultValue, column, optionFilter, isFilterEditor})
  const selected = selectValueLookup(selectOptions, value, isInGrid, defaultValue)

  if (readOnly || disabled) {
    return (
      <TextField
        variant="standard"
        label={isInGrid ? "" : column.title}
        name={column.name}
        required={required && !isFilterEditor}
        value={selected?.name || ''}
        disabled={disabled}
        InputProps={{
          readOnly,
          ...readOnlyInputProps
        }} />
    );
  }

  return (
    <Autocomplete
      autoHighlight
      options={selectOptions}
      getOptionLabel={(option) => option.name}
      value={selected}
      onChange={onChangeHandler}
      style={{ width: isFilterEditor ? '95%' : '100' }}
      renderInput={(params) =>{
        // https://github.com/mui-org/material-ui/pull/18376#issuecomment-558394576
        // extra dummy input is to prevent enter to submit form, see
        // https://stackoverflow.com/a/12123196/429288
        return (
          <form noValidate autoComplete="off">
            <input type="text" style={{display: "none"}} />
            <TextField
              {...params}
              variant="standard"
              name={column.name}
              label={isInGrid ? "" : column.title}
              required={required && !isFilterEditor}
              error={!!error}
              helperText={error}
            />
          </form>
        );
      }}
      {...autocompleteParams}
    />
  );
}

const SelectEditor = (props) => {
  return (
    <TemplateConnector>
      {(getters, actions) => (
        <SelectEditorInner {...props} {...getters} {...actions} />
      )}
    </TemplateConnector>
  )
}

export default SelectEditor
